import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, Link } from "@mui/material";
import Arrow from "../../images/assets/yellowArrow.png";
import { styled } from "@mui/material/styles";
import "../../css/LiveDealsDetails/liveDetails.css";
import DealsFaqs from "./DealsFaqs";
import YoutubeEmbed from "./YouTubeEmbed";
import DealTerm from "./DealTerms";
import { useNavigate } from "react-router-dom";
import Team from "./Team";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShareIcon from "@mui/icons-material/Share";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useSelector } from "react-redux";
import CompanyServices from "../../service/Company";
import services from "../../service/investor.kyc";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import CopyLinkModal from "../CopyLinkModal/CopyLinkModal";
import { useParams } from "react-router-dom";


const PDFViewer = ({ pdfUrl }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js';
    script.async = true;

    script.onload = () => {
      // PDF.js loaded, you can now use it
      const pdfjsLib = window['pdfjs-dist/build/pdf'];
      pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

      // Load and render the PDF
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      loadingTask.promise.then((pdf) => {
        // Fetch the first page
        pdf.getPage(1).then((page) => {
          const canvas = document.getElementById('pdf-canvas');
          const context = canvas.getContext('2d');

          const viewport = page.getViewport({ scale: 1 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          // Render the PDF page into the canvas
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        });
      });
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup: remove the script
      document.body.removeChild(script);
    };
  }, [pdfUrl]);

  return (
    <div>
      <canvas id="pdf-canvas"></canvas>
    </div>
  );
};


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  marginLeft: "1em",
}));
export default function LiveDetailsMainClone() {
  const [activeTab, setActiveTab] = useState(1);
  const [gridxsFirst, setGridxsFirst] = useState(2);
  const [gridxsSecond, setgridxsSecond] = useState(6);
  const ratio = parseInt(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { id } = useParams();
  const [direction, setDirection] = React.useState("right");
  const [hidden, setHidden] = React.useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [hightLightData, setHightLightData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [peopleData, setPeopleData] = useState([]);
  const [kycData, setKycData] = useState({});
  const { userData } = useSelector((state) => state.loginData);
  const [open, setOpen] = useState(false)
  const handleCloseModal = () => { setOpen(false) }
  const [dealTypes, setDealTypes] = useState([])
  const [dealTerms, setDealTerms] = useState([])
  const [superObj, setSuperObj] = useState([])

  const actions = [
    {
      icon: (
        <Link
          style={{ color: "grey" }}
          target="_blank"
          href={`https://www.facebook.com/sharer.php?u=https://mynt-ui.webmavericks.org/live-deals-details/${superObj?.campaignId}`}
        >
          <FacebookRoundedIcon style={{ marginTop: 8 }} />
        </Link>
      ),
      name: "Facebook",
    },
    {
      icon: (
        <Link
          style={{ color: "grey" }}
          target="_blank"
          href={`https://www.instagram.com/sharer.php?u=https://mynt-ui.webmavericks.org/live-deals-details/${superObj?.campaignId}`}
        >
          <InstagramIcon style={{ marginTop: 8 }} />
        </Link>
      ),
      name: "Instagram",
    },
    {
      icon: (
        <Link
          style={{ color: "grey" }}
          target="_blank"
          href={`https://twitter.com/intent/tweet?url=https://mynt-ui.webmavericks.org/live-deals-details/${superObj?.campaignId}&text=Beginner's%20Guide%20to%20Jest%20Testing%20in%20React&via=dannysasse`}
        >
          <TwitterIcon style={{ marginTop: 8 }} />
        </Link>
      ),
      name: "Twitter",
    },
    {
      icon: (
        <Link
          style={{ color: "grey" }}
          target="_blank"
          href={`whatsapp://send?text=https://mynt-ui.webmavericks.org/live-deals-details/${superObj?.campaignId}`}
        >
          <WhatsAppIcon style={{ marginTop: 8 }} />
        </Link>
      ),
      name: "Whatsapp",
    },
    // { icon: <Link style={{color: 'grey'}} target="_blank" href=""><TelegramIcon style={{marginTop:8}} /></Link>, name: "Telegram" },
    {
      icon: (
        <Link
          style={{ color: "grey" }}
          target="_blank"
          href={`https://www.linkedin.com/shareArticle?url=https://mynt-ui.webmavericks.org/live-deals-details/${superObj?.campaignId}&title=Beginner's%20Guide%20to%20Jest%20Testing%20in%20React`}
        >
          <LinkedInIcon style={{ marginTop: 8 }} />
        </Link>
      ),
      name: "Telegram",
    },
  ];

  useEffect(() => {
    services.getInvestorKycData(userData.id).then((response, error) => {
      if (response.status === 200) {
        setKycData(response?.data);
      }
    });

    CompanyServices.getCompanyDetailByCampaign(id).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          console.log("companyData = ", res.data);
          setCompanyData(res.data.company_id);
          setFaqData(res.data.faqs);
          setPeopleData(res.data.company_id.peoples);
          setCampaignData(res.data);
          setHightLightData(res.data.higlights);
        }

      }
    );

    window.scrollTo(0, 0);
    if (ratio < 850) {
      setGridxsFirst(1);
      setgridxsSecond(12);
    }
    setBlurAmount(userData?.id ? 0 : 4.5);
  }, []);
  const [blurAmount, setBlurAmount] = useState(0);
  const { userKycData } = useSelector((state) => state.kycData);

  const handleNavigate = () => {
    !userKycData?.mobile_number_verified
      ? navigate("/complete-your-profile")
      : !userKycData?.pan_card_verified || !userKycData?.pan_card
        ? navigate("/complete-your-profile/verify-kyc")
        : !userKycData?.address_line_1 ||
          !userKycData?.city ||
          !userKycData?.state ||
          !userKycData?.pincode
          ? navigate("/complete-your-profile/verify-address")
          : !userKycData?.aadhaar_card_verified || !userKycData?.aadhaar_card_number
            ? navigate("/complete-your-profile/verify-kyc/aadhar-uid")
            : !userKycData?.bank_account_verified ||
              !userKycData?.ifsc_code ||
              !userKycData?.bank_account ||
              !userKycData?.bank_name
              ? navigate("/complete-your-profile/payment-details")
              : toast.success("Already verified! Please check profile", {
                position: "top-right",
                style: {
                  borderRadius: "3px",
                  background: "green",
                  color: "#fff",
                },
              });
  };

  const daysRemaining = (dateString) => {
    let currentDate = new Date().setDate(1)
    let resDate = new Date(dateString).setDate(1)

    if (currentDate > resDate) {
      return 'Expired'
    } else {
      const now = new Date();
      const targetDate = new Date(dateString);
      const timeDiff = targetDate.getTime() - now.getTime();
      return `${Math.ceil(timeDiff / (1000 * 60 * 60 * 24))} days`
    }

  };

  const calculatePercentage = () => {
    console.log("sjasjasjas", superObj?.campaigns)
    let percentage = superObj?.campaigns?.campaign?.total_raised
    if (percentage > 99) {
      percentage = 100
    }
    return percentage
  }

  const handleEnrollBtn = () => {
    let isvalidate = (daysRemaining(superObj?.campaigns?.deal_terms?.end_date) === 'Expired' || userData?.user_type !== "FOUNDER")
    if (isvalidate) {
      if (kycData?.bank_account &&
        kycData?.pan_card &&
        kycData?.pan_card_verified &&
        kycData?.address_line_1 &&
        kycData?.city &&
        kycData?.state &&
        kycData?.country &&
        kycData?.pincode &&
        kycData?.bank_name &&
        kycData?.bank_account &&
        kycData?.ifsc_code &&
        kycData?.bank_account_verified &&
        kycData?.mobile_number &&
        kycData?.mobile_number_verified &&
        kycData?.aadhaar_card_verified &&
        kycData?.aadhaar_card_number) {
        navigate("/pay-to-enroll", {
          state: {
            campaignId: campaignData.id,
            companyName: companyData.company_name,
            campaignData: superObj?.campaigns
          },
        })
      } else {
        handleNavigate()
      }
    } else {
      return
    }

  }
  useEffect(() => {
    CompanyServices.getAllDealTypes().then(res => {
      if (res.status === 200 || res.status === 201) {
        setDealTypes(res.data)
        if (res?.data?.length > 0) {
          getAllDealTerms()
        }
      } else {
        console.log("Get Deal Type Failed!")
      }
    })
    const getAllDealTerms = () => {
      CompanyServices.getAllDealTerms().then(res => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data?.length > 0) {
            setDealTerms(res?.data?.data)
          }
        } else {
          console.log("Get Deal Terms Failed!")
        }
      })
    }
  }, [])
  const handleArrange = (dealTypes, campaigns) => {
    const result = campaigns.reduce((acc, campaign) => {
      const dealTypeId = campaign?.deal_terms?.security_type;
      const dealType = dealTypes.find((dt) => dt?.id === dealTypeId);
      const campaignObj = { ...campaign };
      const campaignId = campaign?.campaign?.id
      if (acc[dealTypeId]) {
        acc[dealTypeId].campaigns.push(campaignObj);
      } else {
        acc[dealTypeId] = {
          campaignId: campaignId,
          id: dealTypeId,
          deal_type: dealType?.deal_name,
          deal_tagline: dealType?.deal_tagline,
          deal_description: dealType?.deal_description,
          campaigns: [campaignObj],
        };
      }

      return acc;
    }, {});

    const groupedCampaigns = Object.values(result);


    const obj = groupedCampaigns?.find((item) => item?.campaignId == id)
    const superobjj = {
      ...obj,
      campaigns: obj?.campaigns?.find((item) => item?.campaign?.id == id)
    }
    console.log("suu", superobjj)
    setSuperObj(superobjj);
  };

  useEffect(() => {
    return handleArrange(dealTypes, dealTerms);
  }, [dealTypes, dealTerms]);

  useEffect(() => {
    if (!localStorage?.getItem('access_token')) {
      navigate('/login')
    }
  }, [])
  return (
    <>
      <div className="get-started-container">
        <div style={{ paddingTop: "8em" }}>
          <div style={{ display: 'flex', flexDirection: 'row' }} className="deals-detail-video-section" container spacing={gridxsFirst}>
            <Grid className="deals-detail-video-section-left" item xs={gridxsSecond}>
              {campaignData?.youtube_link ? (
                <YoutubeEmbed
                  link={campaignData?.youtube_link}
                  width={gridxsFirst === 2 ? "90%" : "100%"}
                  height={"357px"}
                  embedId={"g_aELYEBc4Q"}
                />
              ) : (
                <div
                  className="investor-home-heading"
                  style={{ fontSize: "16px", marginTop: "8rem" }}
                >
                  {/* No youtube link available for preview */}
                  <video width="90%" height="300" controls>
                    <source src="" type="video/mp4" />
                  </video>
                </div>
              )}
            </Grid>
            <Grid className="deals-detail-video-section-right" item xs={gridxsSecond}>
              <div className="header-section-deals-detail">
                <img src={companyData.company_logo} height={90}></img>
                <span className="live-details-deals-txt-head">
                  {companyData.company_name}
                </span>
              </div>
              <div className="header-section-deals-detail">
                {campaignData?.company_id?.sector.includes(',') ? (
                  campaignData?.company_id?.sector.split(",").map((item, index) => (
                    <div key={index} className="chipset-details-live">
                      {item.trim()} {/* Trim to remove extra whitespace */}
                    </div>
                  ))
                ) : (
                  <div className="chipset-details-live">
                    {campaignData?.company_id?.sector}
                  </div>
                )}
              </div>
              <div
                style={{ filter: `blur(${blurAmount}px)` }}
                className="live-deals-details-decription-conatiner"
              >
                <span className="live-deals-details-decription">
                  {companyData.product_description}
                </span>
              </div>
              <div className="footer-card-section live-details">
                <div className="numbers-investors live-details">
                  <span
                    className="percentage-investment"
                    style={{ fontSize: "20px" }}
                  >
                    {Number(superObj?.campaigns?.campaign?.total_raised).toFixed(2) || '0'}%
                  </span>
                  <span className="investment-status">Completed</span>
                </div>
                <div className="vertical-line-invest live-details"></div>
                <div className="numbers-investors live-details">
                  <span
                    className="percentage-investment live"
                    style={{ fontSize: "20px" }}
                  >
                    {daysRemaining(superObj?.campaigns?.deal_terms?.end_date)}
                  </span>
                  <span className="investment-status live">End Date </span>
                </div>
                <div className="vertical-line-invest live-details"></div>
                <div className="numbers-investors live-details">
                  <span
                    className="percentage-investment live"
                    style={{ fontSize: "20px" }}
                  >
                    {superObj?.campaigns?.deal_terms?.min_subscription || 'N/A'}
                  </span>
                  <span className="investment-status live">Min Enrollment</span>
                </div>
              </div>
              <div className="outline-progress-bar-live-deals">
                <div className="inline-progress-bar-live-deals" style={{ width: `${calculatePercentage()}%` }}></div>
              </div>
              <div className="header-section-deals-detail btn-section">

                <Button
                  onClick={handleEnrollBtn}
                  className={
                    (daysRemaining(superObj?.campaigns?.deal_terms?.end_date) === 'Expired' || userData?.user_type === "FOUNDER")
                      ? "disable-enroll-btn"
                      : "invest-btn-section-deals"
                  }
                >
                  ENROLL NOW
                </Button>
                <StyledSpeedDial
                  sx={{
                    "& .MuiFab-primary": {
                      backgroundColor: "#E3E3E3",
                      color: "black",
                      borderRadius: 50,
                    },
                    "& .MuiFab-primary:hover": {
                      backgroundColor: "#F0C127",
                      color: "black",
                    },
                  }}
                  ariaLabel="SpeedDial playground example"
                  hidden={hidden}
                  icon={<ShareIcon />}
                  direction={direction}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      classes={{
                        ".MuiFab-circular": {
                          borderRadius: 50,
                        },
                      }}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                    />
                  ))}
                </StyledSpeedDial>
                {/* <div className="rounded-container-share-btn"><img src={Share} width={25} height={27}></img></div> */}
              </div>
            </Grid>
          </div>
          <div
            className="heading-live-deals-details"
            style={{ filter: `blur(${blurAmount}px)` }}
          >
            <h2 className="header-txt-deals-details">Ask Me Anything</h2>
            <span
              className="sub-header-txt-deals"
              style={{ maxWidth: "645px", margin: "auto" }}
            >
              Mynt users can interact directly with the founders in a 60 mins
              online session and ask any questions they have regarding the
              campaign.
            </span>
          </div>
          {campaignData?.ama_youtube_video ? <div style={{ paddingTop: '3em' }}>
            <div className="live-deals-box-date" style={{ margin: 'auto' }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Recorded on
              </span>
              {/* <span
                className="header-txt-deals-details"
                style={{ fontFamily: "poppins", fontSIze: "18px" }}
              >
                07:00 PM
              </span> */}
              <span className="live-deals-details-decription">{campaignData?.ama_date}</span>
            </div>
          </div> : null}

          <div
            className="date-booked-register-section"
            style={{ filter: `blur(${blurAmount}px)` }}
          >

            {!campaignData?.ama_youtube_video ?
              <div className="live-deals-box-date second">
                <span style={{ fontSize: '14px', fontWeight: 600 }}>Book A Spot</span>
                <div onClick={() => { campaignData?.ama_meet_link && setOpen(true) }} className="btn-register-live-deals"><span>Register</span><img style={{ marginLeft: '10px' }} src={Arrow} width={8} height={10}></img></div>
              </div>
              :
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                <YoutubeEmbed link={campaignData?.ama_youtube_video} width={gridxsFirst === 2 ? '486px' : '100%'} height={'271.6px'} embedId={"g_aELYEBc4Q"} />
              </div>
            }

          </div>

          {hightLightData?.length > 0 && (
            <>
              <div className="heading-live-deals-details">
                <span className="header-txt-deals-details">Highlights</span>
              </div>
              <div style={{ marginTop: "30px" }}>
                <Grid container spacing={gridxsFirst}>
                  {hightLightData?.map((item, index) => (
                    <Grid key={index} item xs={gridxsSecond}>
                      <Card className="card-content-live-details">
                        <CardContent>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={item?.highlight_image} height={47} style={{ objectFit: 'cover' }} width={53}></img>
                            <span
                              style={{
                                fontSize: "18px",
                                marginLeft: "25px",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </>
          )}
          <div className="tabs-section-details-deals">
            <div className="horizontal-ruler-tabs"></div>
            <div className="btn-section-my-profile details">
              <div
                className="active-btn-container details"
                style={
                  activeTab === 1
                    ? { background: "black", color: "white" }
                    : { background: "#F4F4F4", color: "black" }
                }
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Pitch
              </div>
              <div
                className="active-btn-container details"
                style={
                  activeTab === 2
                    ? { background: "black", color: "white" }
                    : { background: "#F4F4F4", color: "black" }
                }
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                FAQs
              </div>
              <div
                className="active-btn-container details"
                style={
                  activeTab === 3
                    ? { background: "black", color: "white" }
                    : { background: "#F4F4F4", color: "black" }
                }
                onClick={() => {
                  setActiveTab(3);
                }}
              >
                Deal Terms
              </div>
              <div
                className="active-btn-container details"
                style={
                  activeTab === 4
                    ? { background: "black", color: "white" }
                    : { background: "#F4F4F4", color: "black" }
                }
                onClick={() => {
                  setActiveTab(4);
                }}
              >
                People
              </div>
            </div>
            <div className="horizontal-ruler-tabs"></div>
          </div>
          {activeTab === 2 && (
            <DealsFaqs faqData={faqData} companyData={companyData} />
          )}
          {activeTab === 1 && (
            <div>
              <div
                className="investor-home-heading"
                style={{ fontSize: "24px", marginTop: "60px" }}
              >
                {companyData.company_name} - Pitch
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // marginTop: "40px",
                  filter: `blur(${blurAmount}px)`,
                }}
              >
                <PDFViewer pdfUrl={`https://docs.google.com/gview?url=${companyData?.company_pitch}&embedded=true`} />
                {/* <iframe
                src={`http://docs.google.com/gview?url=${companyData?.company_pitch}&embedded=true`}
                style={{ width: "500px", height: "500px" }}
                frameBorder="0"
              ></iframe> */}
                <object
                  className=""
                  data={`https://docs.google.com/gview?url=${companyData?.company_pitch}&embedded=true`}
                  type="application/pdf"
                  // width="800px"
                  height="1000px"
                >
                  <p>
                    Alternative text - include a link{" "}
                    <a
                      href={`https://docs.google.com/gview?url=${companyData?.company_pitch}&embedded=true`}
                    >
                      to the PDF!
                    </a>
                  </p>
                </object>
                {/* <img
                src={FirstImage}
                height={gridxsFirst === 1 ? "193" : "592"}
                style={

                  gridxsFirst === 1
                    ? { width: "90%", margin: "auto" }
                    : { width: "90%", margin: "auto" }
                }
              ></img>
              <img
                src={SecondImage}
                height={gridxsFirst === 1 ? "171" : "592"}
                style={
                  gridxsFirst === 1
                    ? { width: "90%", margin: "auto", paddingTop: "30px" }
                    : { width: "90%", margin: "auto", paddingTop: "30px" }
                }
              ></img>
              <img
                src={ThirdImage}
                height={gridxsFirst === 1 ? "179" : "592"}
                style={
                  gridxsFirst === 1
                    ? { width: "90%", margin: "auto", paddingTop: "30px" }
                    : { width: "90%", margin: "auto", paddingTop: "30px" }
                }
              ></img> */}
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div>
              <div
                className="investor-home-heading"
                style={{ fontSize: "24px", marginTop: "60px" }}
              >
                Deal Terms
              </div>
              <DealTerm
                companyData={companyData}
                blurAmount={blurAmount}
                dealTermData={superObj?.campaigns}
              />
            </div>
          )}
          {activeTab === 4 && (
            <div>
              <div
                className="investor-home-heading"
                style={{
                  fontSize: "24px",
                  marginTop: "60px",
                  marginBottom: "30px",
                }}
              ></div>
              <Team blurAmount={blurAmount} peopleData={peopleData} />
            </div>
          )}
        </div >
      </div >
      <CopyLinkModal show={open} handleClose={handleCloseModal} campaignData={campaignData} />
    </>
  );
}
