import React from "react";
import "../css/termsAndCondition.css";
import { Container, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import Footer from "../component/Footer";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <div className="terms-condition-container">
          <span style={{textAlign:'center',marginBottom: 20}} className="heading-terms-condition"><h2>Privacy Policy</h2></span>
          <span className="paragraph-terms-condition">
            The privacy policy (“Privacy Policy”) governs the use of the website
            of the Mynt platform [https://www.myntinvest.com/] (“MXi”,
            “Website”, “we”, “us”) and the services (“Services”) being provided
            on the Website. This Privacy Policy has been duly framed in
            accordance with the Information Technology Act, 2000 (“Act”) and the
            Rules made thereunder to ensure maximum protection to the
            information provided by its users (“you”). By using the Website, you
            agree to abide by the terms and conditions pertaining to collection,
            retention, and use of information set forth in this Privacy Policy.
            If you do not agree to the Privacy Policy, you may exit and cease to
            use the Website. This Privacy Policy is incorporated in the Terms
            and Conditions of the Mynt platform and any other agreement, in
            which there is a specific clause incorporating the Privacy Policy in
            such agreement. In the event of a conflict between the terms of such
            agreements and the Privacy Policy, the terms of such agreements will
            prevail and govern so long as they relate to matters specifically
            referenced herein and this Privacy Policy will apply with respect to
            all other matters.
          </span>
          <span className="paragraph-terms-condition">
            We are committed to protecting and respecting your privacy.
          </span>
          <span className="paragraph-terms-condition">
            “User” or “You” means any person who accesses the Mynt Website/ App/
            Platform or avails this Service of the Company for the purpose of
            hosting, publishing, sharing, transacting, displaying, interacting,
            considering or uploading information or views and includes other
            persons jointly participating in using the Mynt Website/ App/
            Platform or any Service of the Company.
          </span>
          <span className="paragraph-terms-condition">
            Collectively, we refer to the Terms and Conditions, and the Privacy
            Policy as the “Agreement”. The Agreement is a binding contract
            between You and the Company regarding your use of Mynt Website/ App/
            Platform.
          </span>
          <span className="paragraph-terms-condition">
            MXi operates and provides different offerings which we make
            available through our services, features, products, solutions, apps
            and our websites or other offerings (the “Mynt Website/ App/
            Platform”). We respect the privacy of everyone who visits and uses
            the Mynt Website/ App/ Platform and are committed to maintain the
            privacy and security of the personal information of all visitors to
            the Mynt Website/ App/ Platform. This policy (the “Privacy Policy”)
            describes the information we collect, how it is used and shared, and
            your choices regarding this information. This Privacy Policy
            explains our practices regarding the collection, use, disclosure,
            and protection of information that is collected through our Mynt
            Website/ App/ Platform, as well as your choices regarding the
            collection and use of information. This Privacy policy applies to
            all Users of our Mynt Website/ App/ Platform. Please read the
            following carefully along with the Terms and Conditions of the
            Company before using the Mynt Website/ App/ Platform of the Company.
            Your use of the Mynt Website/ App/ Platform of the Company implies
            your consent to the following terms of privacy, thus please ensure
            that you have read the same.
          </span>
          <span className="paragraph-terms-condition">
            We may ask you to submit personal information in order for you to
            benefit from certain features (such as newsletter subscriptions,
            tips/pointers, or order processing) or to participate in a
            particular activity (such as sweepstakes or other promotions). You
            will be informed what information is required and what information
            is optional.
          </span>
          <span className="paragraph-terms-condition">
            We may combine the information you submit with other information we
            have collected from you, whether on or offline, including, for
            example, your purchase history. We may also combine it with
            information we receive about you from other sources, such as other
            Johnson & Johnson Affiliates, publicly available information sources
            (including information from your publicly available social media
            profiles), and other third-party information providers.
          </span>
          <span className="paragraph-terms-condition">
            If you submit any personal information relating to another
            individual to us, you represent that you have the authority to do so
            and to permit us to use the information in accordance with this
            Privacy Policy.
          </span>
          <span className="paragraph-terms-condition">
            If your child has submitted Personal Information and you would like
            to request that such Personal Information be removed, please contact
            us as explained below under Contacting Us.
          </span>
          <span className="paragraph-terms-condition">
            Unless we specifically request or invite it, we ask that you not
            send us, and you not disclose, any sensitive personal information
            (e.g., Identity document numbers, information related to racial or
            ethnic origin, political opinions, religion or philosophical
            beliefs, health, sex life or sexual orientation, criminal
            background, or trade union membership, or biometric or genetic data
            for the purpose of uniquely identifying an individual) on or through
            the Service or otherwise to us.
          </span>
          <h3>1. HOW WE COLLECT AND USE INFORMATION</h3>
          <span className="paragraph-terms-condition">
            <b>Registration and Profile Information.</b> When you create an
            account, we may collect your personal information such as your
            username, first, middle, and last name, educational information,
            gender, languages, location, email address, mobile phone number and
            a photo. If you want to add a family member’s name to your account,
            we collect the family member’s name, email address, and mobile phone
            number. We may also collect billing information when you sign up.
          </span>
          <span className="paragraph-terms-condition">
            <b>
              Data collected through use of the Mynt Website/ App/ Platform.
            </b>{" "}
            After you set up your account, we may ask for other profile and
            demographic information.
          </span>
          <span className="paragraph-terms-condition">
            <b>Use of Services.</b> When you use the Services to communicate
            with other Users, whether by public posting, direct message or other
            communication features available through Services, such
            communications are transmitted and stored by us. There
            communications are considered public and there is no expectation of
            privacy. We can monitor such communications in order to ensure
            safety and by using the communications features in our Services, you
            are indicating that you agree to such monitoring. Please use common
            sense and good judgement when you participate in these community
            areas.
          </span>
          <span className="paragraph-terms-condition">
            <b>Servers – Cloud.</b> Our servers and cloud automatically record
            certain information about how a person uses our Services including
            both account and non account holders. This information can include
            but not limited to users IP address, brower type, operating system,
            websites they have visited before accessing Mynt Website/ App/
            Platform etc.
          </span>
          <span className="paragraph-terms-condition">
            <b>Location Information.</b> When you use the Mynt Website/ App/
            Platform we can collect and store information about your location by
            converting your IP address into a rough geo location and/or by
            accessing your mobile devices GPS coordinates. If you do not want us
            to collect location information using your mobile device’s location
            services you can disable that feature on your mobile device.
          </span>
          <span className="paragraph-terms-condition">
            <b>Contacts.</b> You can import your personal contacts from your
            mobiles and computer system in order to find people you might
            already know on the Mynt Website/ App/ Platform. If you choose to do
            this, the Mynt Website/ App/ Platform will store these details.
          </span>
          <span className="paragraph-terms-condition">
            <b>Revision of Privacy Policy.</b> Any information that is collected
            via Mynt Website/App/Platform is covered by the Terms and Conditions
            together with Privacy Policy. We can revise the Terms and Conditions
            and/or Privacy Policy from time to time. If we make changes we will
            update the “Last Updated Date” above to indicate when those changes
            will become effective.
          </span>
          <span className="paragraph-terms-condition">
            <b>Social Media.</b> When you interact with our site through various
            other social media, such as when you Like us on Social media Site/
            App/ Platforms, such as Facebook, Twitter, and others or post a
            comment to our Social media Site/ App/ Platforms, such as Facebook,
            Twitter, and others page, we may receive information from that
            social network such as your profile information, profile picture,
            gender, user name, user ID associated with your social media
            account, age range, language, country, friends list, and any other
            information you permit the social network to share with third
            parties. The data we receive is dependent upon your privacy settings
            with those social network. You should always review, and if
            necessary, adjust your privacy settings on third-party websites and
            Mynt Website/ App/ Platform before linking or connecting them to
            MXi’s website or Mynt Website/ App/ Platform.
          </span>
          <span className="paragraph-terms-condition">
            <b>
              Information we collect automatically through the use of
              technology.
            </b>{" "}
            When you visit our website, use our mobile app, read our emails or
            otherwise engage with us, we and our business partners may
            automatically collect certain information about your computer or
            device through a variety of tracking technologies, including
            cookies, web beacons, log files, embedded scripts, GPS coordinates,
            location-identifying technologies, or other tracking/recording tools
            (collectively, “tracking technologies”), and we may combine this
            information with other personal information we collect about you. We
            use these tracking technologies to collect usage and device
            information, such as:
          </span>
          <div className="list-items-container-terms">
            {/* <span className="heading-terms-condition">
              <b>1.</b> HOW WE COLLECT AND USE INFORMATION
            </span> */}
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Information about how you access the Mynt Website/
              App/ Platform, for example, referral/exit pages, how frequently
              you access the Mynt Website/ App/ Platform, whether you open
              emails or click the links contained in emails, whether you access
              the Mynt Website/ App/ Platform from multiple devices, and other
              actions you take on the Mynt Website/ App/ Platform.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Information about how you use the Mynt Website/
              App/ Platform, for example, the features you use, the links you
              click, the ads you view and click on, purchase transaction
              information, your location when you access or interact with our
              Mynt Website/ App/ Platform, and other similar actions.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Information about the computer, tablet, smartphone
              or other device you use, such as your IP address, browser type,
              Internet Mynt Website/ App/ Platform provider, Site/ App/ Platform
              type, device type/model/manufacturer, operating system, phone
              number, mobile carrier, date and time stamp, a unique ID and/or QR
              Code, OTP that allows us to uniquely identify your browser, mobile
              device or your account (including, for example, a UDID, IDFA,
              Company AdID, Windows Advertising ID or other persistent device
              identifier or Ad ID), battery life, and other such information.
              With your permission, we may also access your photo or camera
              roll.
            </span>
            {/* <span className="paragraph-terms-condition">
              <b>d.</b> We may also collect certain automatically generated
              information including the IP address, date and time of visiting
              the Website, online activity. We may engage third-party services
              to monitor and collect such information that will enable us to
              verify your credentials, maintain reasonable security practices,
              enable inclusion of better Services, fulfill your requests and
              enhance your user experience. Further, we may also collect cookies
              (a piece of software code that the Website automatically sends to
              your browser when you access the Website) that enable us to
              provide you a better user experience. We do not link the
              information we store on the cookies to any personally identifiable
              information submitted by you. Also, some of the business partners’
              i.e., third parties operating in association with the Website have
              cookies on our Website. The use of cookies by them is subject to
              the privacy policy and other terms and conditions governing their
              website (“Automatically Generated Information”), Personal
              Information, Other Information, and Automatically Generated
              Information shall together be referred to as “User Information”
            </span>
            <span className="paragraph-terms-condition">
              <b>e.</b> The User Information provided by you may be used by us
              and provided to third party websites, affiliates, consultants,
              employees in order to manage, collect, store, process the User
              Information in order to improve the quality, design,
              functionality, and content of the Website and to any governmental
              authority under applicable law.
            </span>
            <span className="paragraph-terms-condition">
              <b>f.</b> The User Information provided by you shall be used to
              communicate with us through newsletters, updates, notifications,
              and other forms of communication. Further, we may telephonically
              contact you to collect or verify the information provided by you.
              The communication with you might be recorded but will be kept
              confidential otherwise when asked to disclose to any governmental
              authority under applicable law.
            </span>
            <span className="paragraph-terms-condition">
              <b>g.</b> The User Information shall be used for purposes only in
              relation to the Website and not for any other purposes. The User
              Information shall be retained till the termination of your
              membership/listing on the mynt platform or up to such other period
              as may be considered necessary by us for the purpose of operating
              the Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>h.</b> You shall have the right to view the information
              provided by you and also to update the information and keep us
              informed of any such change in case the information provided is
              deficient or inaccurate.
            </span>
            <span className="paragraph-terms-condition">
              <b>i.</b> You undertake that the Personal Information and Other
              Information provided by you is true and accurate to the best of
              your knowledge. You agree and understand that we shall not be
              liable for the authenticity of the Personal Information and Other
              Information provided by you.
            </span>
            <span className="paragraph-terms-condition">
              <b>j.</b> You shall provide us with all the information requested
              from you on the Website or through any other mode of
              communication. You are not legally obligated to provide us with
              all the information. However, the complete functionality of the
              Website shall not be rendered possible if you fail to provide us
              with the certain necessary information for the purpose of the
              Services of the Website. Without prejudice to the aforesaid, you
              shall have an option too, while availing the Services otherwise,
              withdraw your consent to allow us to use the Personal Information
              provided by you and intimate us of the same in writing.
            </span> */}
          </div>
          <span className="paragraph-terms-condition">
            We may collect analytics data, or use third-party analytics tools,
            to help us measure traffic and usage trends for the Mynt Website/
            App/ Platform and to understand more about the demographics of our
            users. We use this information to (i) remember information so that
            you will not have to re-enter it during your visit or the next time
            you visit the Mynt Website/ App/ Platform;(ii) provide custom,
            personalized content and information, including targeted content and
            advertising; (iii) track your location and movements; (iv) identify
            and contact you across multiple devices; (v) provide and monitor the
            effectiveness of our Mynt Website/ App/ Platform and our marketing
            campaigns; (vi) monitor aggregate metrics such as total number of
            visitors, users, traffic, and demographic patterns; (vii) diagnose
            or fix technology problems reported by our Users or engineers that
            are associated with certain IP addresses or User IDs; and
            (viii)detect or prevent fraud or other harmful activities; (xi)
            automatically update our mobile application on your system and
            related devices; and (x) otherwise plan for and enhance our Mynt
            Website/ App/ Platform. If you would prefer not to accept cookies,
            most browsers will allow you to: (i) change your browser settings to
            notify you when you receive a cookie, which lets you choose whether
            or not to accept it; (ii) disable existing cookies; or (iii) set
            your browser to automatically reject cookies. Please note that doing
            so may negatively impact your experience using the Mynt Website/
            App/ Platform, as some features and Mynt Website/ App/ Platform on
            our Mynt Website/ App/ Platform may not work properly. Depending on
            your mobile device and operating system, you may not be able to
            delete or block all cookies. These browser settings that block
            cookies may have no effect on non-cookie technologies. You may also
            set your e-mail options to prevent the automatic downloading of
            images that may contain technologies that would allow us to know
            whether you have accessed our e-mail and performed certain functions
            with it. Although we do our best to honor the privacy preferences of
            our Users, we are not able to respond to ‘Do Not Track’ signals from
            your browser at this time. We and our third-party partners may also
            use information collected through tracking technologies for
            advertising purposes. Wev Beacons are tiny graphics with a unique
            identifier that can be included to deliver or communicate with
            Cookies, to track and measure the performance of our Services, to
            monitor how many visitors view our Services and to monitor the
            effectiveness of our Advertising. Unlike Cookies, which are stored
            on the users hard drive. Web Beacons are typically embedded
            invisibly on web pages (or in a email).
          </span>
          <span className="paragraph-terms-condition">
            We and our service providers may automatically collect and use
            information in the following ways as you navigate around the
            Service:
          </span>
          <span className="paragraph-terms-condition">
            Through your browser: Certain information is collected by most
            browsers, such as your Media Access Control (MAC) address, computer
            type (Windows or Mac), screen resolution, operating system name and
            version, and Internet browser type and version. We may collect
            similar information, such as your device type and identifier, if you
            access the Service through a mobile device. We use this information
            to ensure that the Service functions properly.
          </span>
          <span className="paragraph-terms-condition">
            Using cookies: Cookies are pieces of information stored directly on
            the computer you are using. Cookies allow us to collect information
            such as browser type, time spent on the Service, pages visited, and
            language preferences. We and our service providers use the
            information for security purposes, to facilitate navigation, display
            information more effectively, and to personalize your experience
            while using the Service. We also use cookies to recognize your
            computer or device, which makes your use of the Service easier, such
            as to remember what is in your shopping cart. In addition, we use
            cookies to gather statistical information about Service usage in
            order to continually improve its design and functionality,
            understand how individuals use it, and to assist us with resolving
            questions regarding it. Cookies further allow us to select which of
            our advertisements or offers are most likely to appeal to you and
            display them while you are on the Service. We may also use cookies
            in online advertising to track consumer responses to our
            advertisements.
          </span>
          <span className="paragraph-terms-condition">
            Using pixel tags, web beacons, clear GIFs, or other similar
            technologies: These may be used in connection with some Service
            pages and HTMLformatted email messages to, among other things, track
            the actions of users and email recipients, measure the success of
            our marketing campaigns, and compile statistics about Service usage
          </span>
          <span className="paragraph-terms-condition">
            Interest-based advertising: We may use third-party advertising
            companies to serve advertisements regarding goods and services that
            may be of interest to you when you access and use the Service and
            other online services, based on information relating to your access
            to and use of the Service and other online services on any of your
            devices. To do so, these companies may place or recognize a unique
            cookie on your browser (including through the use of pixel tags).
            They may also use these technologies, along with information they
            collect about your online use, to recognize you across the devices
            you use, such as a mobile phone and a laptop.
          </span>
          <span className="paragraph-terms-condition">
            IP address: Your IP address is a number that is automatically
            assigned to your computer by your Internet Service Provider. An IP
            address is identified and logged automatically in our server log
            files whenever a user visits the Service, along with the time of the
            visit and the pages visited. Collecting IP addresses is standard
            practice and is done automatically by many online services. We use
            IP addresses for purposes such as calculating Service usage levels,
            diagnosing server problems, and administering the Service. We may
            also derive your approximate location from your IP address.
          </span>
          <span className="paragraph-terms-condition">
            Device Information: We may collect information about your mobile
            device, such as a unique device identifier, to understand how you
            use the Service.
          </span>
          <h3>2. SHARING OF YOUR INFORMATION</h3>
          <span className="paragraph-terms-condition">
            <b>Personal Information:</b> We may share your information (without
            personal identifiable details) with third parties outside the
            Company and its controlled subsidiaries and affiliates in some
            circumstances, as follows:
          </span>
          <div className="list-items-container-terms">
            {/* <span className="heading-terms-condition">
              <b>2.</b> Disclosure of User Information
            </span> */}
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> We may share your personal information, including
              your data, unique identifiers and other data with third parties
              for their marketing and business purposes, including without
              limitation, to inform and improve third-party advertising. These
              third-party partners may use this information according to their
              own privacy policies.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Any information or content that you voluntarily
              post to the website or to our Social media Site/ App/ Platforms,
              such as Facebook, Twitter, and others page will automatically be
              available to the public. We may publicly post your reviews and
              comments online on our website or mobile app, or link your reviews
              to third party websites.
            </span>
          </div>
          <span className="paragraph-terms-condition">
            <b>Other instances in which we may share your information:</b> We
            may also share your information for business purposes, as follows:
          </span>
          <div className="list-items-container-terms">
            {/* <span className="heading-terms-condition">
              <b>3.</b> Links to third-party websites
            </span> */}
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> We may share your information (without personal
              identifiable details) with our third-party business partners,
              vendors and consultants who perform Mynt Website/ App/ Platform on
              our behalf or who help us provide our Mynt Website/ App/ Platform,
              such as accounting, managerial, technical, marketing or analytic
              Mynt Website/ App/ Platform.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> We may at anytime buy or sell/divest the company,
              or any combination of its products, Mynt Website/ App/ Platform,
              assets and/or businesses. Personal information such as customer
              names and email addresses, and other user information related to
              the Mynt Website/ App/ Platform will likely be one of the items
              transferred in these types of transactions. We may also share,
              transfer or assign such information in the course of corporate
              divestitures, mergers, acquisitions, bankruptcies, Mynt Website/
              App/ Platform or similar transactions or proceedings.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> We may disclose personal information to third
              parties (including law enforcement, other government entity, or
              civil litigant; excluding our subcontractors) when required to do
              so by law or subpoena or if in our sole discretion, we determine
              it is necessary to (a) conform to the law, comply with legal
              process, or investigate, prevent, or take action regarding
              suspected or actual illegal activities; (b) to enforce our Terms
              of Use, take precautions against liability, to investigate and
              defend ourselves against any claims or allegations, or to protect
              the security or integrity of our site; and/or (c) to exercise or
              protect the rights, property, or personal safety of MXi, our Users
              or others.
            </span>
          </div>
          <span className="paragraph-terms-condition">
            <b>
              De-Identified, Aggregated, or Otherwise and Anonymized
              Information:
            </b>{" "}
            We share your information with any third parties in de-identified,
            aggregated or otherwise anonymized form that does not reasonably
            identify you directly as an individual or your device. These third
            parties may use the anonymized information for any purpose.
          </span>
          <h3>3. Notification and Updates sent by the Website</h3>
          <span className="paragraph-terms-condition">
            MXi cares about the security of your information and uses
            commercially reasonable physical, administrative, organisational,
            legal and technological safeguards to preserve the integrity and
            security of all information we collect and that we share with our
            Mynt Website/ App/ Platform providers and to prevent any accidental
            or unlawful destruction, loss, change or damage. However, no
            security system is impenetrable and we cannot guarantee the security
            of our systems 100%. In the event that any information under our
            control is compromised as a result of a breach of security, we will
            take reasonable steps to investigate the situation and where
            appropriate, notify those individuals whose information may have
            been compromised and take other steps, in accordance with any
            applicable laws and regulations. We will never send you unsolicited
            emails or contact you by phone requesting your account ID, password,
            credit or debit card information or national identification numbers.
          </span>
          <h3>4. USE OF INFORMATION COLLECTED</h3>
          <span className="paragraph-terms-condition">
            We collect and use information for the purposes including but not
            limited to:
          </span>

          <div className="list-items-container-terms">
            {/* <span className="heading-terms-condition">
              4. Notification and Updates sent by the Website
            </span> */}
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to enable smooth and efficient delivery of Services
              to the users with a view to ensure their safety
            </span>
            <span className="paragraph-points-terms-conditionn">
              <b>&#9679;</b> To enhance the safety and security of our users and
              services
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> For customer support
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> For research and development
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To enable communications to or between users
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> In connection with legal proceedings
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To download product information, order products and
              take advantage of certain other features of our Mynt Website/ App/
              Platform.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To provide information or interactive services
              through this Mynt Website/ App/ Platform, to your e-mail address
              or, where you wish it to be sent by post, to your name and postal
              address.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Live location sharing
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Personal data for identity cards, real time
              attendance, cashless canteens, tracking, Library, medical history,
              hobbies management, report generation, management, notifications
              etc.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Personal data for identity cards
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> Storage of history and data of Mynt Website/ App/
              Platform.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To seek your feedback or to contact you in relation
              to those services offered on our Mynt Website/ App/ Platform.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To process orders or applications submitted by you.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To administer or otherwise carry out our
              obligations in relation to any agreement you have with us.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To anticipate and resolve problems with any goods
              or services supplied to you.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To create products or services that may meet your
              needs.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To process and respond to requests, improve our
              operations, and communicate with visitors about our products,
              services and businesses.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To allow you to subscribe to our newsletter.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To provide the functionality of the Service to you
              and providing you with related customer service;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To respond to your inquiries and fulfill your
              requests, such as to send you documents you request or email
              alerts;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> To send you important information regarding our
              relationship with you or regarding the Service, changes to our
              terms, conditions, and policies and/or other administrative
              information.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> For data analysis, for example, to improve the
              efficiency of the Service;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for audits, to verify that our internal processes
              function as intended and are compliant with legal, regulatory, or
              contractual requirements;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for fraud and security monitoring purposes, for
              example, to detect and prevent cyberattacks or attempts to commit
              identity theft;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for developing new products and services;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for enhancing, improving or modifying our website
              or products and services;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for identifying Service usage trends, for example,
              understanding which parts of our Service are of most interest to
              users; and
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> for determining the effectiveness of our
              promotional campaigns, so that we can adapt our campaigns to the
              needs and interests of our users.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to better understand you, so that we can
              personalize our interactions with you and provide you with
              information and/or offers tailored to your interests;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to better understand your preferences so that we
              can deliver content via the Service that we believe will be
              relevant and interesting to you.
            </span>

            {/* <span className="paragraph-terms-condition">
              <b>b.</b> Out of courtesy towards your privacy, we give you the
              option to opt-out of any Services on the Website. If you choose
              not to opt-out of any particular Service, you agree to get
              information regarding such Services. In order to clarify, we only
              send you information if you have chosen to receive it. In case you
              wish to discontinue any Services, you can choose to unsubscribe
              such Services by choosing the unsubscribe option in the emails
              sent to you or tabs available on the Website. However, you shall
              not be able to opt-out of any Service notification that may be
              necessary in the process of the Website or that may be important
              to you as a member of the mynt platform.
            </span> */}
          </div>
          <span className="paragraph-terms-condition">
            We also disclose information collected through the Service:
          </span>
          <div className="list-items-container-terms">
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to our affiliates for the purposes described in
              this Privacy Policy.
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to our third party partners with whom we offer a
              cobranded or comarketed promotion;
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> to our third party service providers who provide
              services such as website hosting and moderating, mobile
              application hosting, data analysis, payment processing, order
              fulfillment, infrastructure provision, IT services, customer
              service, email and direct mail delivery services, auditing, and
              other services, in order to enable them to provide services; and
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679;</b> as permitted by applicable law, to a third party in
              the event of any reorganization, merger, sale, joint venture,
              assignment, transfer, or other disposition of all or any portion
              of our business, assets, or stock (including in connection with
              any bankruptcy or similar proceedings).
            </span>
            {/* <span className="heading-terms-condition">
              <b>5.</b> Security Practices
            </span>
            <span className="paragraph-terms-condition">
              <b>a.</b> The User Information shall be governed by and protected
              by us according to the security practices and procedures mandated
              under the Act and more particularly described under the
              Information Technology (Reasonable Security Practices & Procedures
              and Sensitive Personal Data or Information) Rules, 2011 and
              Information Technology (Intermediary Guidelines) Rules, 2011.
            </span> */}
          </div>

          <span className="paragraph-terms-condition">
            In addition, we may use and disclose your information as we believe
            to be necessary or appropriate: (a) to comply with legal process or
            applicable law, which may include laws outside your country of
            residence; (b) as permitted by applicable law to respond to requests
            from public and government authorities, which may include
            authorities outside your country of residence; (c) to enforce our
            terms and conditions; and (d) to protect our rights, privacy,
            safety, or property, and/or that of our affiliates, you, or others.
            We may also use and disclose your information in other ways, after
            obtaining your consent to do so.
          </span>
          <span className="paragraph-terms-condition">
            We may use and disclose information we collect automatically as
            described above, under “Automatic Information Collection and Use.”
          </span>
          <span className="paragraph-terms-condition">
            In addition, where allowed by applicable law, we may use and
            disclose information that is not in personally identifiable form for
            any purpose. If we combine information that is not in personally
            identifiable form with information that is identifiable (such as
            combining your name with your geographical location), we will treat
            the combined information as personal information as long as it is
            combined.
          </span>
          <span className="paragraph-terms-condition">
            We will retain your personal information for as long as needed or
            permitted in light of the purpose(s) for which it was obtained. The
            criteria used to determine our retention periods include: (i) the
            length of time we have an ongoing relationship with you and provide
            the Service to you; (ii) whether there is a legal obligation to
            which we are subject; and (iii) whether retention is advisable in
            light of our legal position (such as in regard to applicable
            statutes of limitations, litigation, or regulatory investigations).
          </span>
          <h3>5. SHARING AND DISCLOSURE OF INFORMATION</h3>
          <span className="paragraph-terms-condition">
            We will not use or share, either within MXi or with a third party,
            any information collected at this page for direct marketing
            purposes. Because of the nature of the Internet, we can transmit the
            information to another country, but among MXi and its affiliates,
            for purposes other than direct marketing, such as for storage, or
            for carrying out the processing detailed above, or because of where
            our servers are located, but we do not provide or use personal
            information to unrelated businesses for direct marketing purposes.
            We may also remove all the personally identifiable information and
            use the rest for historical, statistical or scientific purposes. If
            you e-mail us, you are voluntarily releasing information to us. Your
            e-mail address will be used by MXi to respond to you. We will not
            use the information that can identify you, such as your e-mail
            address, for direct marketing purposes. In addition, we may have
            collected similar information from you in the past. By entering this
            Mynt Website/App/Platform you are consenting to the terms of our
            information Terms of Use and to our continued use of previously
            collected information. By submitting your personal information to
            us, you will be treated as having given your permission for the
            processing of your personal data as set out in this policy.
          </span>
          <h3>6. COOKIES</h3>
          <span className="paragraph-terms-condition">
            We may store some information such as cookies on your computer when
            you look at our Mynt Website/ App/ Platform. Cookies are pieces of
            information that a Mynt Website/ App/ Platform transfers to the hard
            drive of a visitor’s computer for record-keeping purposes. This
            information facilitates your use of our Mynt Website/ App/ Platform
            and ensures that you do not need to re-enter your details every time
            you visit it. You can erase or choose to block this information from
            your computer if you want to; please refer to your browser settings
            to do so. Erasing or blocking such information may limit the range
            of features available to the visitor on our Mynt Website/ App/
            Platform. We also use such information to provide visitors a
            personalised experience on our Mynt Website/ App/ Platform. We may
            use such information to allow visitors to use the Mynt Website/ App/
            Platform without logging on upon returning, to auto-populate email
            forms, to make improvements and to better tailor our Mynt Website/
            App/ Platform to our visitors’ needs. This information can verify
            that visitors meet the criteria required to process their requests
          </span>
          <h3>7. SECURITY</h3>
          <span className="paragraph-terms-condition">
            We have implemented reasonable high level technology security and
            policies, with the objective of protecting your privacy from
            unauthorised access, breach and improper use, and periodically
            review the same. However, no security system is impenetrable and we
            cannot guarantee the security of our systems 100%.
          </span>
          <h3>8. THIRD PARTIES</h3>
          <span className="paragraph-terms-condition">
            For your convenience, Mynt Website/ App/ Platform may contain
            certain hyperlinks linked to another Third-Party and Mynt Website/
            App/ Platform may, from time to time, contain links to and from
            third party websites, including those of other users, our partner
            networks, advertisers, partner merchants, news publications,
            retailers, webeacons, and affiliates. We are not responsible for the
            privacy policies and practices of other Third Parties, even if you
            access those using links from our Mynt Website/ App/ Platform, as
            they have their own privacy policies. We can make no promises or
            guarantees regarding data collection on the hyper-linked pages and
            on Mynt Website/ App/ Platforms that are not owned by MXi. We
            recommend that you check the policy of each Third Parties you visit,
            or link from, and contact the owners or operators of such Mynt
            Website/ App/ Platforms if you have any concerns or questions. We
            are not responsible for the practices employed by Mynt Website/ App/
            Platform linked to or from the Mynt Website/ App/ Platform, nor the
            information or content contained therein.
          </span>
          <h3>9. RETENTION AND DELETION</h3>
          <span className="paragraph-terms-condition">
            MXi requires user profile information in order to provide its
            services. It retains such information for as long you avail the
            services of the Company. MXi retains certain information, including
            transaction, location, device and usage information, for reasonable
            amount of time in connection with regulatory, tax, insurance and
            other requirements in the places in which it operates. Once such
            information is no longer necessary to provide MXi’ services, enable
            customer support, to enhance the user experience or for other
            operational purposes, MXi takes steps to prevent access to or use of
            such information for any purpose other than compliance with these
            requirements or for purposes of safety, security and fraud
            prevention and detection. You may request deletion of your account
            information at any time in the Mynt Website/ App/ Platform.
            Following such a request, MXi deletes the information that it is not
            required to retain. In certain circumstances, MXi may be unable to
            delete your account such as if there is an outstanding credit on
            your account or an unresolved claim or dispute. Upon resolution of
            the issue preventing deletion, MXi may delete your account as
            described above. MXi may also retain certain information and/or
            historical data if necessary, for its legitimate business interests,
            such as fraud prevention and enhancing users’ safety and security.
          </span>
          <h3>10. YOUR CHOICES ABOUT YOUR INFORMATION</h3>
          <span className="paragraph-terms-condition">
            You may, of course, decline to submit any personal information
            through the Mynt Website/ App/ Platform, in which case MXi may not
            be able to provide its Mynt Website/ App/ Platform to you.
          </span>
          <span className="paragraph-points-terms-condition">
            <b>Delete Your Account and Personal Information:</b> We will retain
            your information for as long as your account is active or as needed
            to provide you Mynt Website/ App/ Platform. If you wish to cancel
            your account, please contact us at our email address listed on our
            website.
          </span>
          <span className="paragraph-points-terms-condition">
            <b>Third Party Tracking and Advertising:</b> We may share, or we may
            permit third party ad networks, social media companies, and other
            third party Mynt Website/ App/ Platform to collect, information
            about your use of our Mynt Website/ App/ Platform over time,
            including location information, so that they may play or display ads
            that may be relevant to your interests on our Mynt Website/ App/
            Platform as well as on other websites, apps or Mynt Website/ App/
            Platform, or on other devices you may use. We may also display
            targeted advertising to you through social media Site/ App/
            Platforms, such as Social media Site/ App/ Platforms, such as
            Facebook, Twitter, and others, these third part ad partners may
            collect information such as click stream information, time stamp,
            hashed email address, device ID, your use of third-party
            applications and/or precise geolocation data and other information,
            and may use your unique identifiers to recognize you across various
            devices you use. We and our advertising partners use this
            information to make the advertisements you see online more relevant
            to your interests, as well as to provide advertising-related Mynt
            Website/ App/ Platform such as reporting, attribution, analytics,
            and market research.
          </span>
          <b>To opt-out or control third-party tracking and advertising:</b>
          <div className="list-items-container-terms">
            <span>
              <b>&#9679; Mobile advertising.</b>
            </span>
          </div>
          <span className="paragraph-terms-condition">
            You may also be able to limit interest-based advertising through the
            settings on your mobile device by selecting “limit ad tracking”
            (iOS) or “opt-out of interest-based ads” (Android). You may also be
            able to opt out of some but not all interest-based ads served by
            mobile ad networks.
          </span>
          <span className="paragraph-terms-condition">
            <b>&#9679; Company Analytics and Advertising.</b>We may also utilize
            certain forms of display advertising and other advanced features
            through Company Analytics. These features enable us to use
            first-party cookies (such as the Company Analytics cookie) and
            third-party cookies (such as the Double click advertising cookie) or
            other third-party cookies together to inform, optimize, and display
            ads based on your past visits to the Mynt Website/ App/ Platform.
            You may control your advertising preferences or opt-out of certain
            Company advertising
          </span>
          <span className="paragraph-terms-condition">
            <b>Third Party Location Sharing:</b> We collect location data to
            provide the MXi Mynt Website/ App/ Platform, and may share your
            personal information, including location data, with other MXi users,
            and with third parties for advertising or other purposes.
          </span>
          <div className="list-items-container-terms">
            <span className="paragraph-points-terms-condition">
              <b>&#9679; Limit location sharing.</b> You may turn location
              sharing “OFF” through your App settings. If you turn off location
              sharing, we will not share your location; however, we will
              continue to collect your location information in order to provide
              the Mynt Website/ App/ Platform (for example, to provide our
              emergency Mynt Website/ App/ Platform, to share your location with
              the other circles, or to provide location-based advertising).
            </span>
            <span className="paragraph-points-terms-condition">
              <b>&#9679; Limit location collection and sharing.</b> You can
              opt-out of future sharing of your precise location data with our
              third-party partners by adjusting your settings on your mobile
              device to prohibit MXi from collecting your location data. For
              example, depending on your mobile device, you may be able to “Turn
              location Mynt Website/ App/ Platform ‘off.’ You can also adjust
              your device settings to withdraw permission for the MXi app to
              access location data. We and our third-party partners may continue
              to approximate your location based on other data. Please note that
              your Mynt Website/ App/ Platform may not be able to provide all of
              our Mynt Website/ App/ Platform to you if you disable access to
              location data through your device settings.
            </span>
          </div>
          <h3>11. CHANGES TO OUR PRIVACY POLICY</h3>
          <span className="paragraph-terms-condition">
            MXi may, in its sole discretion, modify or update this Privacy
            Policy from time to time, and so you should review this page
            periodically. When we change the policy, we will update the ‘last
            modified’ date at the top of this page.
          </span>
          <h3>12. MARKETING AND ADVERTISING</h3>
          <span className="paragraph-points-terms-condition">
            A) From time to time, we may contact you with information about our
            products and Mynt Website/ App/ Platform. Most of the marketing
            messages we send will be by email, SMS or through push notifications
            or related communications on your device.
          </span>
          <span className="paragraph-points-terms-condition">
            B) Your acceptance to this Privacy Policy and Agreement will enable
            us to contact you and permit you to receive marketing messages
            unless you have not provided your consent to do so.
          </span>
          <span className="paragraph-points-terms-condition">
            C) You can change your preferences at a later date by clicking on
            the unsubscribe link at the bottom of our marketing newsletters.
          </span>
          <span className="paragraph-points-terms-condition">
            D) Please note that if you do opt-out of or do not grant consent to
            receiving marketing related messages from us, we may still send you
            non-marketing messages, such as communications relating to the
            provision of our Mynt Website/ App/ Platform.
          </span>
          <h3>13. Through Our Platform</h3>
          <span className="paragraph-points-terms-condition">
            We share some of the information that we collect by displaying it on
            our Mynt Website/ App/ Platform according to your member profile and
            account settings. Some information, such as username, is always
            public. Some information, such as interests, is public by default,
            but can be hidden on Mynt Website/ App/ Platform. Some information,
            such as group memberships, will always be visible to other members
            of that Mynt Website/ App/ Platform group, and may be public,
            depending on the settings of that group. We recommend that you check
            the group settings and what information will be available before
            joining the group to ensure that you are happy with the information
            that is visible to others.
          </span>
          <h3>14. HOW TO CONTACT US</h3>
          <span className="paragraph-points-terms-condition">
            If you have any questions about this Privacy Policy or the Mynt
            Website/ App/ Platform, please contact us at through our website
            with the Subject of the email: Your Name, Privacy Terms.
          </span>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
export default PrivacyPolicy;
