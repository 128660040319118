import axios from "axios";
import { Base_Url } from "../Utils/Configurable";
import { toast } from "react-hot-toast";

const notify = (data) => {
  toast.error(data, {
    position: "top-right",
    style: {
      borderRadius: "3px",
      background: "red",
      color: "#fff",
    },
  });
};
const notifySuccess = (data) => {
  toast.success(data, {
    position: "top-right",
    style: {
      borderRadius: "3px",
      background: "green",
      color: "#fff",
    },
  });
};

const VerifyEmailOtp = async (data) => {
  if (data.otp === "") {
    notify("otp can not be empty !!");
  } else {
    try {
      const response = await axios.post(
        `${Base_Url}/api/users/verify-email-otp`,
        { email: data.email, otp: data.otp }
      );
      notifySuccess(response.data.message);
      return response;
    } catch (error) {
      if (error) {
        notify("Invalid OTP !!");
      }
      return error;
    }
  }
};

const ResendOtpMail = async (data) => {
  try {
    const response = await axios.post(`${Base_Url}/api/users/send-otp`, data);
    // notifySuccess(response.data.message)
    return response;
  } catch (error) {
    if (error) {
      notify("Try again !!");
    }
    return error;
  }
};
const SecondarySendOtpMail = async (data) => {
  try {
    const response = await axios.post(`${Base_Url}/api/users/send-otp`, 
    { secondary_email: data}
    );
    // notifySuccess(response.data.message)
    return response;
  } catch (error) {
    if (error) {
      notify("Try again !!");
    }
    return error;
  }
};

const VerifyMobileOtp = async (data) => {
  try {
    const response = await axios.post(
      `${Base_Url}/api/investor-kyc/mobile/manage`,
      data
    );
    notifySuccess("OTP sent on your mobile number!");
    return response;
  } catch (error) {
    if (error) {
      notify("Try again !!");
    }
    return error;
  }
};

const VerifyMobileOtpPatch = async (data) => {
  try {
    const response = await axios.patch(
      `${Base_Url}/api/investor-kyc/mobile/manage`,
      data
    );
    notifySuccess("OTP sent on your mobile number!");
    return response;
  } catch (error) {
    if (error) {
      notify("Try again !!");
    }
    return error;
  }
};

const MobileOtp = async (data) => {
  if (data.otp === "") {
    notify("otp can not be empty !!");
  } else {
    try {
      const response = await axios.post(
        `${Base_Url}/api/investor-kyc/mobile/verify-otp`,
        data
      );

      return response;
    } catch (error) {
      if (error) {
        notify("Invalid OTP !!");
      }
      return error;
    }
  }
};
const SecondaryEmailVerification = async (data) => {
  if (data.otp === "") {
    notify("otp can not be empty !!");
  } else {
    try {
      const response = await axios.post(
        `${Base_Url}/api/users/secondary-email-verify`,
        data
      );

      return response;
    } catch (error) {
      if (error) {
        notify("Invalid OTP !!");
      }
      return error;
    }
  }
};

const OtpServices = {
  VerifyEmailOtp,
  ResendOtpMail,
  VerifyMobileOtp,
  VerifyMobileOtpPatch,
  MobileOtp,
  SecondaryEmailVerification,
  SecondarySendOtpMail
};

export default OtpServices;