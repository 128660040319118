import React from "react";
import "../css/termsAndCondition.css";
import { Container, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import Footer from "../component/Footer";
const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <div className="terms-condition-container">
          <h2  style={{textAlign:'center',marginBottom: 50}}>TERMS & CONDITIONS</h2>
          <span className="heading-terms-condition">Terms of Use</span>
          <span className="paragraph-terms-condition">
            Please read the following Terms of Use / Terms and Conditions
            carefully as it sets out the terms of a legally binding agreement
            between you and MXi Technologies Private Limited (“MXi”, “Mynt” or
            “Company” “We”, “Our”, or “Us”). MXi has its own separate identity
            but we may sometimes use “MXi”, “Mynt”, “Company”, “we”, “our” or
            “us” when we refer t(Last Updated On 16th May) MXi in general.
          </span>
          <div className="list-items-container-terms">
            <span className="paragraph-points-terms-condition">
              &#10146; These Terms of Use / Terms and Conditions (“Terms”)
              govern your access to and your use of our services, including our
              websites, SMS, Application programming interface (API), email
              notifications, applications, buttons, widgets, ads, mobile
              applications, features, technologies and software and our other
              related and covered services as well. Brands under MXi include but
              not limited to, MXi and any other brand launched by MXi, the
              content offered through website [https://www.Myntinvest.com/]
              (“Website”) and/or the MXi applications available on Android as
              well as IOS and MXi Social Media presence (including but not
              limited to Twitter, YouTube, Facebook, Instagram, etc.)
              (Hereinafter collectively referred to “Mynt Website/App/Platform”)
              or any mobile or internet connected device or otherwise and any
              services offered by the Company (collectively, the “Service”). By
              accessing the Mynt Website/App/Platform or using the Service, you
              agree to be bound by these Terms. Therefore, these Terms are to be
              read carefully before continuing to use the Service. If you do not
              agree with any of these Terms, please do not use the
              Website/App/Platform or Service.
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; These Terms of Use including the Privacy Policy, and
              Risks (“Terms of Use”) govern the use of the website of MXi
              platform [https://www.Myntinvest.com/] (“Website”) and the
              services (“Services”) provided on the MXi platform. By using the
              Mynt Website/App/Platform including but not limited to accessing
              or visiting or browsing the Mynt Website/App/Platform, you
              (“Visitor/User/Member”) indicate your acceptance to these Terms of
              Use and that you agree to abide by them. The Terms of Use
              constitute a legal agreement between you, as the user of the Mynt
              Website/App/Platform, and us, the owner of the Mynt
              Website/App/Platform. If you do not agree to these Terms of Use,
              please refrain from using this Mynt Website/App/Platform. The
              Terms of Use shall be considered as part of any agreement, the
              reference to which shall be made in that agreement, and shall be
              read along with the terms and conditions of that agreement. In the
              event of a conflict between the terms of such agreements and the
              Terms of Use, the terms of such agreements will prevail and govern
              so long as they relate to matters specifically referenced herein
              and this Terms of Use will apply with respect to all other
              matters.{" "}
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; You also acknowledge and agree that we can modify these
              Terms at any time, in our sole discretion; that all modifications
              to this Agreement will be effective immediately upon our posting
              of the modifications on this Mynt Website/App/Platform or Service;
              and that you will review these Terms each time you access this
              Mynt Website/App/Platform or Service, so that you are aware of and
              agree to any and all modifications made to this Agreement. Your
              use of the Mynt Website/App/Platform or Service after any
              amendment to the Terms shall constitute your acceptance of these
              terms and you also agree to be bound by any such
              changes/revisions.
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; Supplemental terms can apply to certain Services, such as
              policies for a particular event, activity or promotion, and such
              supplemental terms will be disclosed to you in connection with the
              applicable Services. Supplemental terms are in addition to, and
              shall be deemed a part of, the Terms for the purposes of the
              applicable Services. Supplemental terms shall prevail over these
              Terms in the event of a conflict with respect to the applicable
              Services.
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; “User” or “You” means any person who accesses or avails
              this Service of the Company for the purpose of uploading,
              downloading, hosting, publishing, sharing, transacting,
              displaying, interacting, considering, issuing, downloading or
              uploading information or views and includes other persons jointly
              participating in using the Mynt Website/App/Platform or Service of
              the Company.{" "}
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; You agrees to comply with all applicable law, rules and
              regulations relevant to the Mynt Website/App/Platform or Service
              of the Company. The User also warrants that its execution of this
              Agreement and use of Mynt Website/App/Platform or any Service of
              the Company has been duly authorized by the User so that it is not
              subject to any further approvals or ratification in order to
              become a binding obligation of the User.
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; Collectively, we refer to the Terms and Conditions, and
              the Privacy Policy as the “Agreement”. The Agreement is a binding
              contract between You and Company regarding your use of Mynt
              Website/App/Platform or Service.
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; MXi shall be not held liable for any improper or
              incorrect use of the information or services on Mynt
              Website/App/Platform or Service and assume no responsibility for
              anyone's use of the information or services. The Service is
              designed for Users residing in India and may be extended to other
              parts of the world. The Service may not be extended to areas
              especially the remote areas where there is no mobile network,
              location and no internet services available.{" "}
            </span>
            <span className="paragraph-points-terms-condition">
              &#10146; MXi controls and operates the Mynt Website/App/Platform
              or Service from India and makes no representation that the
              materials are appropriate or will be available for use in other
              locations. If you use this Mynt Website/App/Platform or Service
              from outside the India, you are entirely responsible for
              compliance with all applicable local laws. MXi does not make any
              warranty or representation that a user in one region may obtain
              the Services from the Mynt Website/App/Platform or Service in
              another region and MXi may cancel a User’s order or redirect a
              User to the site for that User’s region if a User attempts to
              order Services offered on a site in another region. Information
              that MXi publishes on the World Wide Web may contain references or
              cross references to MXi products, programs and services that are
              not announced or available in your country. Such references do not
              imply that MXi intends to announce such products, programs or
              services in your country. Consult your local MXi business contact
              for information regarding the products, programs and services that
              may be available to you.
            </span>
          </div>
          <h3>1. Information about us and the MXi platform</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              The Website and platform enables early-stage ventures to display
              their business ideas and run a fundraising campaign on the
              Website, and facilitates potential users to decide regarding
              investing in such ventures. The Website also provides the
              early-stage ventures and the potential users with such other
              information and service as are necessary or incidental to the
              principal activity described here.
            </span>
          </div>
          <h3>2 .Access to the Website</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>2.1</b> By accessing the Website, you agree to these Terms of
              Use and the Privacy Policy set out. The access to certain sections
              of the Website is conditional upon our acceptance of you as a
              “member” or ‘issuing company’ or ‘issuer’ on the MXi Platform. We
              reserve the right to withdraw or amend the Services being provided
              to you through the Website without any notice. We will not be
              liable if for any reason our Website is not available to you at
              any period of time. Further, we have the right to restrict your
              access to the whole or part of the Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>2.2</b> All rights that are not expressly granted to you are
              reserved under these Terms of Use.
            </span>
            <span className="paragraph-terms-condition">
              <b>2.3</b> We take all reasonable steps to ensure that the Mynt
              Website/App/Platform or Service is available 24 hours every day,
              365 days per year. However, Website/App/Platform or Service do
              sometimes encounter downtime due to server and, other technical
              issues. Therefore, we will not be liable for any losses, if this
              Website/App/Platform or Service is unavailable at any time due to
              such issues
            </span>
          </div>
          <h3>3. Fees</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>3.1</b> MXi reserves the right to charge fees for certain
              listings and Services, as well as transaction fees based on
              certain completed transactions using the MXi Service. MXi further
              reserves the right to alter any and all fees from time to time and
              without notice. You can acquire Content on our Services for free
              or for a charge, either of which is referred to as a
              “Transaction.” Each Transaction is an electronic contract between
              you and MXi, and/or you and the entity providing the Content on
              our Services.
            </span>
            <span className="paragraph-terms-condition">
              <b>3.2</b> The User shall be liable to pay all applicable charges,
              fees, duties, taxes, levies and assessments for availing the MXi
              Service. All transactions are final. Prices might change at any
              time. If technical problems prevent or unreasonably delay delivery
              of Website/App/Platform or Service, your exclusive and sole remedy
              is either replacement of the Service or refund (by credit note) of
              the price paid, as determined by MXi.
            </span>
            <span className="paragraph-terms-condition">
              <b>3.3</b> MXi will charge your selected payment method (such as
              your credit card, debit card, gift card/code, or other method) for
              any paid Transactions, including any applicable taxes. If you have
              also Credit Points, MXi may charge your selected payment method in
              Credit Points. If we cannot charge your selected payment method
              for any reason (such as expiration or insufficient funds), you
              remain responsible for any uncollected amounts, and we will
              attempt to charge the payment method again as you may update your
              payment method information. If you have any Credit Points, MXi
              balance, MXi attempts to use your MXi balance to cover the full
              amount of the bill.{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>3.4</b> If you don’t have an MXi balance or you don’t have
              enough balance to cover the full amount, MXi attempts to charge
              your primary payment method. If there’s a problem with the primary
              payment method, or if the primary payment method can’t be charged
              for the full remaining amount, MXi attempts to charge your other
              payment methods. If a purchase fails to charge to all of the
              payment methods that you have on file, a negative balance can be
              applied to you. You won’t be able to make any new purchases and
              use any other services until you’ve updated your payment methods.
              After you update your payment methods, the negative balance
              charges immediately to your updated primary payment method.
            </span>
            <span className="paragraph-terms-condition">
              <b>3.5</b> Fees are exclusive of applicable taxes, but our Mynt
              Website/App/Platform or Service shall state on the final payment
              invoice where any additional taxes are applicable and payable.{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>3.6</b> Except for non-payment of amounts subject to good faith
              and reasonable dispute where the User shall promptly notify
              Company (in no event later to 2 (two) days after receipt of the
              invoice) in the event that any amount set forth on any invoice is
              in dispute. Any portion that is not disputed shall be paid by the
              User to the Company. Any such partial payment shall not, however,
              constitute a waiver by the recipient of such payment of any other
              rights. The Company and the User shall cooperate in good faith to
              investigate any such dispute and endeavor to resolve amicably the
              circumstances surrounding such dispute, which resolution shall be
              deemed to occur, in the event the dispute arises due to
              insufficient detail or documentation, upon the presentation by the
              User of adequate detail or documentation, and establish a suitable
              amount to be paid; otherwise, if the parties are unable to resolve
              any such dispute, it shall be subject to the dispute resolution
              procedures set forth in this Agreement. If the User fails to make
              any payment to the Company within the stipulated day after it is
              due, interest shall accrue on the overdue amount, from the date
              overdue until the date paid, equal to ten percent (10%) of the
              amount overdue together with a penalty late charge of Indian
              Rupees 5,000 (five thousand) per day for each month outstanding
              until full payment, beginning with the due date of the late
              payment.
            </span>
          </div>
          <h3>4. Other Charges and Payment:</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>4.1</b> All other Charges related to user of Services provided
              by MXi will be borne by the User.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.2</b> You shall pay applicable fees on the Mynt
              Website/App/Platform or Service and for Services by using a
              Debit/Credit Card, Online Payment Method or through your Internet
              Banking.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.3</b> You submit and agree that the Payment details provided
              by you to the Payment Gateway for payment transaction processing
              will be correct and you shall not use Payment Details, which is
              not lawfully owned by You.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.4</b> You agree that when you initiate a payment transaction
              and/or issue an online payment Instruction and provide your
              Payment details.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.5</b> You warrant that you are fully and lawfully entitled to
              use the said Debit/Credit Card or Bank Account for such payment.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.6</b> You are responsible to ensure that all the Debit/Credit
              Card or Bank Account details provided by you are correct.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.7</b> You are authorizing the debit to the nominated
              Debit/Credit Card or Bank account for the settlement of bills
              selected by you along with the applicable fees.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.8</b> You are responsible to ensure that sufficient credit is
              available on your presented Debit/Credit Card or Bank Account at
              the time of making the payment to permit the payment of the bill/s
              selected by you inclusive of the applicable fees.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.9</b> To complete the transaction, you will be directed to
              such specific Third-Party entity whose services are being utilized
              by MXi to facilitate the proposed transaction of the User in such
              proportion and in such manner as may be agreed between such Third
              Party and MXi.
            </span>
            <span className="paragraph-terms-condition">
              <b>4.10</b> The User further agrees that MXi do not have any
              control over any portion of the Services to be provided by such
              Third Parties and consequently MXi does not have any control over
              any payments transferred to such Third Party pursuant to the
              agreement between such third-party entities and MXi.
            </span>
          </div>
          <h3>5. Cancellations, Refunds and Charge backs</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>51</b> If the MXi receives a request from a User for refund or
              cancellation of successful payment in relation to any transaction,
              the MXi will process the request and determine whether such refund
              or cancellation must be processed. The request for refund or
              cancellation of payment in relation to any transaction by the User
              shall be done immediately within 24 (twenty-four) hours to MXi in
              writing. If the said request must be processed, the MXi will
              determine at its sole discretion and will notify User of the same
              and MXi within 60 (sixty) days that the refund will be provided.
              Your exclusive and sole remedy for refund or cancellation is
              either replacement of the Content or refund (by credit note) of
              the price paid, as determined by MXi{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>5.2</b> Whenever a refund is agreed to be made by the MXi to a
              User, the MXi will issue a credit slip to User within 30 days
              after the refund has been agreed to be made by the MXi to the
              User. The User shall not be entitled to any interest or
              compensation on account of any delay in refund of such payments by
              MXi or its affiliates.
            </span>
            <span className="paragraph-terms-condition">
              <b>5.3</b> MXi shall be entitled to apply a handling fee for any
              cancellation and refund and provide any refund in the form of a
              credit note. Payment of such refunds will be subject to the
              express condition that settlement of all invoices for which sums
              fall in line with the base of the turnover used as a basis to
              ensure satisfaction of the condition to benefit from the refund
              has been made upon due dates as determined, without limitation.
            </span>
            <span className="paragraph-terms-condition">
              <b>5.4</b> In the event of failure on the part of such Third
              Parties to render the transaction services contemplated or
              intended by the User, the User understands and agrees that MXi or
              its affiliates shall not be liable to refund any payments made by
              the User unless the same is refunded to Company by such Third
              Parties nor shall the User be entitled to any compensation for any
              losses suffered or expenses incurred by reason of such failure or
              for any other reason whatsoever.{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>5.5</b> From time to time, MXi can refuse a refund request if
              we find evidence of fraud, refund abuse, or other manipulative
              behavior that entitles MXi to a corresponding counterclaim.
            </span>
          </div>{" "}
          <h3>6. Registration and Membership</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>6.1</b> If you are interested in exploring opportunities in the
              issuer companies seeking to raise funding through the MXi
              Platform, in order to have full functionality of the Website, you
              will have to register as a ‘member’ on the MXi platform. The terms
              of registration including eligibility criteria and rights after
              becoming a member are available on our Website, and you may view
              them once you become a member of the MXi platform. In case of a
              conflict between the Terms of Use and the specific condition
              governing membership of the MXi Platform, the specific conditions
              of membership shall prevail.
            </span>
            <span className="paragraph-terms-condition">
              <b>6.2</b> If you are interested in running a fundraising campaign
              through the MXi Platform, in order to have full functionality of
              the Website, you will have to register as an ‘issuing company’ or
              the ‘issuer’ on the MXi platform. The terms of registration
              including eligibility criteria and rights after enrolling as an
              ‘issuing company’ or ‘issuer’ are available on our Website. In
              case of a conflict between the Terms of Use and the specific
              condition governing your status as an ‘issuer company’ or ‘issuer’
              on the MXi Platform, the specific conditions applicable to ‘issuer
              company’ or ‘issuer’ shall prevail.
            </span>
          </div>{" "}
          <h3>7. Security to the Website</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>7.1</b> Upon completion of your registration to the Website,
              and upon reasonable satisfaction of our understanding of your
              engagement with us, an OTP to the email ID specified by you shall
              be provided. That OTP confirmation will allow you to participate
              in the fundraising campaign in which you have evinced your
              interest.
            </span>
            <span className="paragraph-terms-condition">
              <b>7.2</b> You agree not to provide your username and password
              information to any other person other than with us.
            </span>
            <span className="paragraph-terms-condition">
              <b>7.3</b> You agree to keep your password secure. You are fully
              responsible for any loss or damage resulting from your failure to
              protect your password. You agree to immediately notify us of any
              unauthorized use of your password or any other breach of the
              security.
            </span>
            <span className="paragraph-terms-condition">
              <b>7.4</b> You agree that we shall not be liable for any loss or
              damage arising out of our failure to keep your password secure.
            </span>
          </div>
          <h3>8. Secure Transactionse</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>8.1</b> MXi has implemented and will maintain security systems
              for the transmission of Users’ Transactions. MXi does not
              guarantee the security of the Services or Transaction data, and
              MXi will not be responsible in the event of any infiltration of
              its security systems, provided that MXi has used commercially
              reasonable efforts to prevent any such infiltration. User further
              acknowledges and agrees that User, and not MXi, is responsible for
              the security of Transaction data or information or any other
              information stored on Users servers, and that MXi is not
              responsible for any other party’s servers (other than
              subcontractors of MXi solely to the extent MXi is liable for its
              own actions hereunder).
            </span>
          </div>
          <h3>9. Representations and Warranties of Users</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>9.1</b> Your entering into these Terms of Use on your own
              behalf or on behalf of the entity for whom you are acting provides
              a representation that you agree to abide by the Terms of Use or
              such other agreements that you may enter into while browsing
              through the Website. You agree to stop using the Website, and
              inform us of any violation of law that may stop you from using our
              Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.2</b> You represent that all the information provided by you
              is true, correct, and accurate and you shall inform us of any
              change/ amendment in such information from time to time.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.3</b> You shall not host, display, upload, modify, publish,
              transmit, update or share any information that: (i) belongs to
              another person and to which you do not have any right to; (ii) is
              grossly harmful, harassing, libellous, invasive of another’s
              privacy, hateful or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              otherwise unlawful in any manner whatsoever; (iii) harms minors in
              any way; (iv) infringes any patent trademark, copyright or other
              proprietary rights; (v) violates any law for the time being in
              force; (vi) deceives or misleads the addressee about the origin of
              such messages or communicates any information which is grossly
              offensive or menacing in nature; (vii) impersonates another
              person; (viii) threatens the unity, integrity, defence, security
              or sovereignty of India, friendly relations with friendly states,
              or public order or causes incitement to the commission of any
              cognisable offence or prevents investigation of any offence or is
              insulting any other nation.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.4</b> You agree and understand that you shall not sell your
              access to the Website. You shall not transmit any unnecessary
              information or unwanted electronic communication viz. spam to
              other members of the MXi platform. You will not misuse your right
              to the Website by introducing viruses, trojans, worms, or other
              material likely to cause harm to the Website and shall indemnify
              and keep us indemnified in case any action is initiated against us
              due to any loss, injury, expenses or liability caused to any other
              user of the Website or any third-party. You shall further not gain
              any unauthorized access to the Website or on any other source to
              our Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.5</b> We expect you to provide and upload accurate and
              complete information of the compulsory fields. We also expect you
              to keep the information secure, specifically access passwords and
              payment information. Kindly update the information periodically to
              keep your account relevant. We will rely on any information which
              you provide to us as we in no way responsible for the correctness
              or details shown in your account or any direct/indirect or
              consequential damages or loss caused due to such information.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.6</b> We also expect you to keep the information secure and
              confidential, specifically access passwords and payment
              information. Therefore, You are responsible for the use of Service
              made by You or anyone else using your ID and for preventing
              unauthorized use of your ID. It is your responsibility that the
              user of the Mynt Website/App/Platform or Service at all times uses
              the username and password appropriately. You take the
              responsibility that your account will not be used in any way that
              contradicts any applicable law or the terms of this mentioned,
              herein. If you believe there has been any breach of security such
              as the disclosure, theft or unauthorized use of your ID or any
              payment information, you must notify MXi immediately by e-mailing
              us.
            </span>
            <span className="paragraph-terms-condition">
              <b>9.7</b> By way of example, and not as a limitation, the User
              agrees and undertakes that when using an MXi Site/App/Platform or
              Service, the User will not:
              <ul>
                <li>
                  defame, abuse, harass, stalk, threaten or otherwise violate
                  the legal rights of others;
                </li>
                <li>
                  upload files that contain software or other material protected
                  by intellectual property laws unless the User owns or controls
                  the rights thereto or have received all necessary consents;
                </li>
                <li>
                  upload or distribute files that contain viruses, corrupted
                  files, or any other similar software or programs that may
                  damage the operation of the Mynt Website/App/Platform or
                  Service or another’s computer;
                </li>
                <li>
                  advertise or offer to sell or buy any goods or services for
                  any business purpose, unless such Mynt Website/App/Platform or
                  Service Service specifically allows such messages;
                </li>
                <li>
                  conduct or forward surveys, contests, pyramid schemes or chain
                  letters;
                </li>
                <li>
                  download any file posted by another user of a Mynt
                  Website/App/Platform or Service that the User know, or
                  reasonably should know, cannot be legally distributed in such
                  manner;
                </li>
                <li>
                  falsify or delete any author attributions, legal or other
                  proper notices or proprietary designations or labels of the
                  origin or source of software or other material contained in a
                  file that is uploaded;
                </li>
                <li>
                  violate any code of conduct or other guidelines, which may be
                  applicable for or to any particular Mynt Website/App/Platform
                  or Service;
                </li>
                <li>
                  violate any applicable laws or regulations for the time being
                  in force in or outside India; and
                </li>
                <li>
                  violate any of the terms and conditions of this Agreement or
                  any other terms and conditions for the use of the Mynt
                  Website/App/Platform or Service contained elsewhere.
                </li>
              </ul>
            </span>
            <span className="paragraph-terms-condition">
              <b>9.8</b> In consideration for MXi granting you access to and use
              of the Services, you agree that MXi and its third-party providers
              and partners may place advertising on the Services or in
              connection with the display of Content or information from the
              Services whether submitted by you or others. You also agree not to
              misuse our Services, for example, by interfering with them or
              accessing them using a method other than the interface and the
              instructions that we provide. You may not do any of the following
              while accessing or using the Services:
              <ol type="a">
                <li>
                  access, tamper with, or use non-public areas of the Services,
                  MXi’ computer systems, or the technical delivery systems of
                  MXi’ providers;{" "}
                </li>
                <li>
                  probe, scan, or test the vulnerability of any system or
                  network or breach or circumvent any security or authentication
                  measures;{" "}
                </li>
                <li>
                  access or search or attempt to access or search the Services
                  by any means (automated or otherwise) other than through our
                  currently available, published interfaces that are provided by
                  MXi (and only pursuant to the applicable terms and
                  conditions), unless you have been specifically allowed to do
                  so in a separate agreement.{" "}
                </li>
                <li>
                  forge any TCP/IP packet header or any part of the header
                  information in any email or posting, or in any way use the
                  Services to send altered, deceptive or false
                  source-identifying information; or{" "}
                </li>
                <li>
                  interfere with, or disrupt, (or attempt to do so), the access
                  of any user, host or network, including, without limitation,
                  sending a virus, overloading, flooding, spamming, mail-bombing
                  the Services, or by scripting the creation of Content in such
                  a manner as to interfere with or create an undue burden on the
                  Services.
                </li>
              </ol>{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>9.9</b> We also reserve the right to access, read, preserve,
              and disclose any information as we reasonably believe is necessary
              to satisfy any applicable law, regulation, legal process or
              governmental request to:
              <ol type="a">
                <li>
                  Enforce the Terms, including investigation of potential
                  violations hereof,
                </li>
                <li>
                  Detect, prevent, or otherwise address fraud, security or
                  technical issues, Respond to user support requests, or Protect
                  the rights, property or safety of MXi and its users and the
                  public.
                </li>
              </ol>
            </span>
            <span className="paragraph-terms-condition">
              <b>9.10</b> Your use of the Mynt Website/App/Platform or Service
              will expire on a pre-determined time and date at the sole
              discretion of the Company. The Company reserves the right to
              terminate your use of the Mynt Website/App/Platform or Service at
              any given point in time if we determine that you have violated any
              provision of this Agreement.
            </span>
          </div>
          <h3>10. User’s Obligations and User Account:</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>10.1</b> In consideration of use of the Mynt
              Website/App/Platform or Service, the User represents and confirms
              that the User is of legal age to enter into a binding contract and
              is not a person barred from using the Mynt Website/App/Platform or
              Service and/or receiving the Services under the laws of India or
              other applicable law.
            </span>

            <span className="paragraph-terms-condition">
              <b>10.2</b> To avail a Service the User has and must continue to
              maintain at his/her sole cost:
              <br />
              1. All the necessary equipment including a phone, computer,
              internet data package, adequate battery, and modem etc. to access
              the Mynt Website/App/Platform or Service
              <br />
              2. Own access to the World Wide Web. The User shall be responsible
              for accessing the Mynt Website/App/Platform or Service and
              Services as well as that access may involve third party fees
              including, airtime charges or internet Service Provider’s charges
              which are to be exclusively borne by the User.
              <br />
              3. The User also understands that the services may include certain
              Mynt Website/App/Platform or Service from MXi as service
              announcements and administrative messages. The User understands
              and agrees that the Services are provided on an “as is” basis and
              that MXi does not assume any responsibility for deletions,
              mis-delivery or failure to store any user Mynt
              Website/App/Platform or Service or personalized
            </span>
            <span className="paragraph-terms-condition">
              <b>10.3</b> Upon completion of the registration process, the User
              shall receive a User Id and Password. The User agrees and
              undertakes at all times to be responsible for maintaining the
              confidentiality of the User Id and Password, and shall be fully
              responsible for all activities that occur by use of such password
              or user id. Further, the User agrees not to use any other party’s
              User Id and Password for any purpose whatsoever without proper
              authorization from such party. You are responsible for the
              security of your password and for all transactions undertaken
              using your password through our service. The Password entered by
              you is transmitted in one-way encrypted form to our database and
              stored as such. Thus the Password will not be known even to MXi.
              You confirm that you are the authorized holder of the credit card
              or the original account holder used in the transactions you make
              using the MXi Services. MXi will not be responsible for any
              financial loss or inconvenience resulting from misuse of your User
              Id/Password/credit card number as well as Financial Details for
              using MXi Services.
            </span>
            <span className="paragraph-terms-condition">
              <b>10.4</b> You shall use the Mynt Website/App/Platform or Service
              appropriately and you must refer to the rules and regulations
              governing use of Mynt Website/App/Platform or Service to ensure
              proper usage by you and the user. You shall also co-operate fully
              with the Company in relation to delivery of the Mynt
              Website/App/Platform or Service at all times.
            </span>
            <span className="paragraph-terms-condition">
              <b>10.5</b> The User also agrees and undertakes to immediately
              notify MXi of any unauthorized use of the User’s Id or Password
              and to ensure that the User logs off at the end of each session on
              the Mynt Website/App/Platform or Service. MXi shall not be
              responsible for any, direct or indirect, loss or damage arising
              out of the User’s failure to comply with this.
            </span>
            <span className="paragraph-terms-condition">
              <b>10.6</b> As a conditional precedent the User also agrees to:
              (a) provide true, accurate and complete information about him/her
              and any beneficiaries as prompted by the registration form
              (“Registration Data”) on the Mynt Website/App/Platform or Service;
              and (b) maintain and promptly update the Registration Data to keep
              it true, accurate, current and complete. If the User provides any
              information that is untrue, inaccurate, current or incomplete or
              MXi has reasonable grounds to suspect that such information is
              untrue, inaccurate, not current or incomplete, MXi has the right
              to suspend or terminate the User’s registration and refuse any and
              all current or future use of the Mynt Website/App/Platform or
              Service and/or any Service. Furthermore, the User grants MXi the
              right to disclose to third parties Registration Data to the extent
              necessary for the purpose of carrying out the Services.
            </span>

            <span className="paragraph-terms-condition">
              <b>10.7</b> As a condition precedent of the use of the Mynt
              Website/App/Platform or Service, the User warrants that they will
              not use the Mynt Website/App/Platform or Service for any purpose
              that is unlawful or illegal under any law for the time being in
              force within or outside India or prohibited by this Agreement
              including both specific and implied. This includes: 1.
              Redistribute or sell any part of Mynt Website/App/Platform or
              Service(s) or create a new product or service based on Mynt
              Website/App/Platform or Service; 2. Copy the content; 3. Mass
              download or create bulk feeds of the Content (or let anyone else
              do so); 4. Use Mynt Website/App/Platform or Service(s) to create
              or augment any other mapping- related dataset (including a mapping
              or navigation dataset, business listings database, mailing list,
              social media, or telemarketing list) for use in a service that is
              a substitute for, or a substantially similar service to, MXi
              Site/App/Platform(s); 5. Reverse engineer or attempt to extract
              the source code from MXi Site/App/Platform(s) or any related
              software, except to the extent that this restriction is expressly
              prohibited by applicable law; 6. Remove, obscure, or alter any
              terms of service or any links to or notices of those terms, or any
              copyright, trademark, or other proprietary rights notices; or 7.
              Do anything inappropriate, illegal, or in violation of others’
              rights (including their privacy, publicity, and intellectual
              property rights)
            </span>
            <span className="paragraph-terms-condition">
              <b>10.8</b> In addition, the Mynt Website/App/Platform or Service
              shall not be used in any manner, which could damage, disable,
              overburden or impair it or interfere with any other party’s use
              and/or enjoyment of the Mynt Website/App/Platform or Service. The
              User shall refrain from obtaining or attempting to obtain any
              materials or information through any means not intentionally made
              available or provided for or through the Mynt Website/App/Platform
              or Service..
            </span>
            <span className="paragraph-terms-condition">
              <b>10.9</b> You agree that you will NOT use the
              Website/App/Platform or Service to impersonate any person or
              entity in person or electronically or forge anyone else’s digital
              or manual signature, including, but not limited to, an official of
              the Company or its employees, a forum leader, guide or host, or
              falsely state or otherwise misrepresent your affiliation with a
              person or entity. You shall also not appear to represent the
              Company through the use of or reference to the Company’s name in
              any emails without the express written consent of the Company.
            </span>
            <span className="paragraph-terms-condition">
              <b>10.10</b> You may not impersonate others through the
              Website/App/Platform or Service in a manner that does or is
              intended to mislead, confuse, or deceive others..
            </span>
            <span className="paragraph-terms-condition">
              <b>10.11</b> You shall not impersonate any person or entity or
              otherwise misrepresent their affiliation with a person or entity
              or interfere with other Users. You shall not participate in the
              collection of very large numbers of email addresses, screen names,
              or other identifiers of others (without their prior consent), a
              practice sometimes known as “spidering” or “harvesting,” or
              participate in the use of software (including “spyware”) designed
              to facilitate this activity. You agree that you will not use any
              robot, spider, scraper or other automated means to access this
              Website/App/Platform or Service for any purpose without our
              express written permission. .
            </span>
            <span className="paragraph-terms-condition">
              <b>10.12</b> Although it has taken what it believes to be
              commercially reasonable efforts to design Mynt
              Website/App/Platform or Service features, to the extent permitted
              by law, MXi makes no warranties, express or implied, about the
              operation, performance, availability or results of the Mynt
              Website/App/Platform or Service, including (without limitation)
              that the features will successfully validate and provide any
              Service. Company is not liable for the actions or inactions of any
              User or any other third party..
            </span>
            <span className="paragraph-terms-condition">
              <b>10.13</b> Data Protection. You authorize the release from time
              to time to the Company (and any of its Subsidiaries or Affiliates)
              and to its affiliates (together, the “Relevant Companies”) of any
              and all personal or professional data that is necessary or
              desirable for the administration of the Website/App/Platform or
              Service and/or this Agreement (the “Relevant Information”).
              Without limiting the above, You permit his or her allowing the
              Company to collect, process, register and transfer to the Relevant
              Companies all Relevant Information (including any personal data
              that may be useful or necessary for the purposes of the
              administration of the Website/App/Platform or Service and/or this
              Agreement and/or to implement or structure any Service). You
              hereby authorize the Relevant Information to be transferred to any
              jurisdiction that the Company, his or her employing company or the
              affiliates considers appropriate as per applicable law.
            </span>
          </div>
          <h3>11. Content in the the Mynt Website /APP/Solution(s). </h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>11.1</b> You are responsible for your use of the Service and
              for any information, documents, time entry, location, text, links,
              graphics, photos, videos, or other materials or arrangements of
              materials uploaded, downloaded or appearing on the Services
              (collectively referred to as “Content”) you provide, including
              compliance with applicable laws, rules, and regulations. You
              should only provide Content that you are comfortable sharing with
              others.
            </span>
            <span className="paragraph-terms-condition">
              <b>11.2</b> Any use or reliance on any Content or materials posted
              via the Services or obtained by You through the Services is at
              your own risk. We do not endorse, support, represent or guarantee
              the completeness, truthfulness, accuracy, or reliability of any
              Content or communications posted via the Services or endorses any
              opinions expressed via the Services. You understand that by using
              the Services, you may be exposed to Content that might be
              offensive, harmful, inaccurate or otherwise inappropriate, or in
              some cases, postings that have been mislabeled or are otherwise
              deceptive. All Content is the sole responsibility of the person
              who originated such Content. We may not monitor or control the
              Content posted via the Services and, we cannot take responsibility
              for such Content.
            </span>
          </div>
          <h3>12. Our rights in relation to the Website</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>12.1</b> We have the right to discontinue or change our
              Services at any time and shall not be liable for the same.
            </span>
            <span className="paragraph-terms-condition">
              <b>12.2</b> We shall delete your account or cancel access to the
              Website for any reason whatsoever, at any time at our discretion.
              We shall also suspend or limit your access to the Website as and
              when may be considered necessary. For the aforesaid, we shall make
              all reasonable efforts to notify you and inform you of such an
              action and the reasons thereof, in any.
            </span>
            <span className="paragraph-terms-condition">
              <b>12.3</b> We shall disclose such confidential information as may
              be provided by you or such other details about yourself as may be
              necessary to satisfy any governmental department or authority
              under applicable law or to any third-party in accordance with the
              terms of the Privacy Policy.
            </span>
            <span className="paragraph-terms-condition">
              <b>12.4</b> With reference to above Clauses , you agree and
              understand that we shall not be liable for any claim based on any
              termination, suspension or any of the aforesaid actions taken by
              us in relation to your access to the Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>12.5</b> We may invite you to participate in the chat rooms or
              other features that will give you an opportunity to know about the
              MXi platform, the companies and the fundraising campaigns of such
              companies. The comments or other information provided by you on
              such chat rooms shall be deemed to have been licensed to us on a
              free and permanent basis.
            </span>
            <span className="paragraph-terms-condition">
              <b>12.6</b> MXi and the Website/App/Platform or Service will not
              be deemed in default hereunder or held responsible for any
              cessation, interruption or delay in the performance of its
              obligations hereunder due to reasons beyond reasonable control
              including but not limited to Acts of God, war (whether declared or
              not), armed conflict or the serious threat of the same (including
              but not limited to hostile attack, blockade, military embargo),
              hostilities, invasion, act of a foreign enemy, extensive military
              mobilization; civil war, riot, rebellion, revolution, military or
              usurped power, insurrection, civil commotion or disorder, mob
              violence, act of civil disobedience; act of terrorism, sabotage or
              piracy; plague, epidemic, pandemic, outbreaks of infectious
              disease or any other public health crisis, including quarantine or
              other employee restrictions; act of authority whether lawful or
              unlawful, compliance with any law or governmental order, rule,
              regulation or direction, curfew restriction, expropriation,
              compulsory acquisition, seizure of works, requisition,
              nationalization; natural disaster such as but not limited to
              violent storm, cyclone, typhoon, hurricane, tornado, blizzard,
              earthquake, volcanic activity, landslide, tidal wave, tsunami,
              flood, damage or destruction by lightning, drought; explosion,
              fire, destruction of machines, equipment, and of any kind of
              installation, prolonged break-down of transport, telecommunication
              or electric current; general labor disturbance such as but not
              limited to boycott, strike and lock-out, go-slow, occupation of
              factories and premises; shortage or inability to obtain critical
              material or supplies to the extent not subject to the reasonable
              control of the subject Party; or any other event similar to those
              enumerated above (“Force Majeure Event”). Such excuse from
              liability and performance shall be effective for the duration of
              the event(s) causing the failure or delay in performance and / or
              for a period at the discretion and determined solely by MXi.
              Notice of a MXi’s failure or delay in performance due to force
              majeure will be given to You through the Mynt Website/App/Platform
              or Service at any time within 36 (thirty six) months after its
              occurrence which notice shall describe the force majeure event and
              the impact thereof. Notwithstanding the foregoing and anything
              contained in the Agreement, should the event(s) of force majeure
              suffered by MXi extend beyond a 7 (seven) days period, MXi may
              terminate this Agreement on its Mynt Website/App/Platform or
              Service forthwith. The right to terminate in the event of such a
              force majeure event rests solely with MXi. You understand and
              agree that Your relationship and agreement with MXi might end if a
              Force Majeure Event occurs beyond the MXi control. MXi shall be
              excused from the performance of its obligations under this
              Agreement under Force Majeure Event. Such excuse shall be
              continued so long as the condition constituting force majeure
              continues. You agree and acknowledge any payment by you and the
              payment of invoices due and owing hereunder by You shall in no
              event be delayed by You because of a force majeure event affecting
              You.We at MXi will not be liable or responsible for any failure to
              perform, or delay in performance of, any of our obligations under
              the Agreements that is caused by any act or event beyond our
              reasonable control, including any failure of public or private
              telecommunications networks.
            </span>
          </div>
          <h3>13. Intellectual Property Rights</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>13.1</b> When you visit our Mynt Website/App/Platform, we give
              you a limited license to access and use our information for
              personal use.
            </span>
            <span className="paragraph-terms-condition">
              <b>13.2</b> You are permitted to download the information
              available on the Mynt Website/App/Platform to any instrument for
              your personal use only provided that you do not delete or change
              any copyright symbol, trademark or other proprietary details. You
              shall not use our information for any other purpose other than for
              the aforesaid. You agree that any use of the proprietary
              information displayed on the Mynt Website/App/Platform shall
              infringe our intellectual property rights for which you shall
              indemnify us.
            </span>
            <span className="paragraph-terms-condition">
              <b>13.3</b> We have copyright on all the contents displayed on the
              Mynt Website/App/Platform including graphics, logo, sound
              recordings and software that is either owned or licensed to us
              other than any third-party contents which are specifically
              identified as such. Any infringement of our intellectual property
              rights shall be governed by the applicable law.
            </span>
            <span className="paragraph-terms-condition">
              <b>13.4</b> The license to access and use the Mynt
              Website/App/Platform does not include the right to copy or
              reproduce the information of our Mynt Website/App/Platform on any
              other platform or medium, without our prior written permission.
            </span>
          </div>
          <div className="list-items-container-terms">
            <h3>14. Linked Websites</h3>
            <span className="paragraph-terms-condition">
              <b>14.1</b> You may be able to access and view third-party
              websites through this Mynt Website/App/Platform. The links are
              provided for your convenience only and may not be updated at all
              times.
            </span>
            <span className="paragraph-terms-condition">
              <b>14.2</b> We do not endorse, review, control or examine
              third-party sites and we are not responsible for any content
              posted on such third-party websites. You understand that the
              inclusion of links on the Website is not intended as an
              endorsement or recommendation of any linked website or content of
              such website.
            </span>
            <span className="paragraph-terms-condition">
              <b>14.3</b> You agree that your access to any third-party sites is
              governed by the terms of use of that website, and has no relation
              to the Terms of Use of the Website. You agree and understand that
              it is your responsibility to comply with the terms and conditions
              of that website as well.
            </span>
            <span className="paragraph-terms-condition">
              <b>14.4</b> MXi, makes no representations or warranties, for
              itself and any third-party providing materials, services, or
              content to Mynt Website/App/Platform or Service, either express,
              implied, statutory or otherwise of merchantability, fitness for a
              particular purpose, or non-infringement of third-party rights,
              with respect to the Mynt Website/App/Platform or Service, the
              information or any products or services to which the information
              refers.
            </span>

            <span className="paragraph-terms-condition">
              <b>14.5</b> Certain links on the Mynt Website/App/Platform or
              Service might lead to resources located on servers maintained by
              third parties and are external to Mynt Website/App/Platform or
              Service. While we strive to provide only links of the useful and
              ethical websites, we have no control over the content and nature
              of such third party links. Therefore, MXi neither endorses nor
              offers any judgement or warranty, and accepts no responsibility or
              liability for the authenticity or availability of any
              goods/services/or for any damage, loss or harm, direct or
              consequential, or any violation of local or international laws
              that may be incurred as a result of your visit and/or
              transaction/s on these third party.
            </span>
          </div>
          <h3>15. Disclaimers and Limitation of Liability</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>15.1</b> You agree and understand that the use of the Mynt
              Website/App/Platform is at your own risk. The Website is being
              made available to you on an “as is” and “as available” basis
              without providing any warranties, guarantees or conditions as to
              the usage being free from any faults, defects, interruptions,
              errors, viruses or to the accuracy, reliability, availability of
              content. You agree and understand that we shall not be responsible
              for any interference or damage that may be caused to your computer
              resource which arises in connection with your access to our
              Website.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.2</b> You also agree and understand that the information
              displayed on the Mynt Website/App/Platform is for information
              purposes only and does not amount to any advice.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.3</b> To the extent permitted by applicable law, we disclaim
              our liability against any loss, damage, expenses, liabilities,
              claim, injury caused due to the failure of performance, omission,
              defect, deletion, interruption, error, delay, virus,
              communication, unauthorized access, theft, destruction, alteration
              or use of records, whether due to breach of the contract,
              negligence, tort or due to other cause of actions.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.4</b> Further, we shall not be responsible for any loss of
              profits, goodwill, revenue, consequential, exemplary, punitive
              damages or any financial or indirect loss.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.5</b> You further acknowledge and agree that we shall not be
              responsible for any defamatory, offensive or illegal conduct of
              third parties on our Website, including users and operators of
              third-party websites. Further, we shall not be responsible or be
              held liable for any inaccuracy, delay, omission or defect,
              transmission or delivery of any third-party data or any loss or
              damage arising from: (i) any inaccuracy, error, delay or omission
              of transmission of information; (ii) non-performance by any
              third-party; or (iii) interruption caused due to any third-party
              due to their negligent act or omission any other cause, not beyond
              the reasonable control of us.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.6</b> Notwithstanding anything in the Terms of Use, the
              total liability for damages actually incurred by you shall be
              limited to Rs. 5,000 (Rupees Five Thousand Only). The aforesaid
              applies to all liabilities in the aggregate, including but not
              limited to, liabilities arising out of the use of the Website, any
              other subject matter arising out of or in relation to the use of
              the Mynt Website/App/Platform.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.7</b> MXi or its affiliates including the directors,
              officers, employees or agents will not be liable to you or any
              third party for any damages of any kind, including but not limited
              to, direct, indirect, incidental, consequential or punitive
              damages, arising from or connected with the Mynt
              Website/App/Platform or Service, including but not limited to,
              your use of this Mynt Website/App/Platform or Service or your
              inability to use the Mynt Website/App/Platform or Service, or your
              inability to use the Mynt Website/App/Platform or Service, or any
              of its contents, or from any act or omissions as a result of using
              the Mynt Website/App/Platform or Service or any such contents or
              for any failure of performance, error, omission, interruption,
              deletion, defect, delay in operation or transmission, computer
              virus, theft, or destruction or unauthorised access to, alteration
              of, or use of information contained on the Mynt
              Website/App/Platform or Service even if MXi has previously been
              advised of the possibility of such damages.
            </span>
            <span className="paragraph-terms-condition">
              <b>15.8</b> You agree that regardless of any statute or law to the
              contrary, any claim or cause of action arising out of or related
              to use of the Mynt Website/App/Platform or Service or Products or
              this Agreement must be filed within one (1) year after such claim
              or cause of action arose or be forever barred.
            </span>
          </div>
          <h3>16. Indemnity</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>16.1</b> You hereby agree to indemnify and hold us harmless
              from and against any loss, damage, expenses, liabilities or claims
              arising out of or in relation to your failure to comply with the
              Terms of Use or any misstatement or breach of any representations
              or warranties made by you under the Terms of Use or under any
              conditions on the Mynt Website/App/Platform accepted by you.
            </span>
          </div>
          <h3>17. Governing Law and Dispute Resolution</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>17.1 Governing Law:</b> The Terms of Use shall be governed by
              and construed in all respects in accordance with the laws of India
              and subject to Clause 11.3 below, the courts of New Delhi shall
              have exclusive jurisdiction.
            </span>
            <span className="paragraph-terms-condition">
              <b>17.2 Informal Dispute Resolution: </b> The Parties agree to
              attempt to resolve all disputes arising hereunder, promptly and in
              good faith and in this regard, each Party shall each designate in
              writing to the other Party, a representative who shall be
              authorized to negotiate and resolve on its behalf any dispute
              arising under these{" "}
            </span>
            <span className="paragraph-terms-condition">
              <b>17.3 Terms of Use:</b> If the designated representatives of
              each of the Parties are unable to resolve a dispute under the
              Terms of Use within 30 (thirty) days after notice of such dispute
              shall have been given by either of the Parties to the other, then
              either Party may require that such dispute be determined and
              resolved by arbitration.
            </span>
            <span className="paragraph-terms-condition">
              <b>17.4 Arbitration: </b> Subject to Clause 11.2, any dispute or
              claim under the Terms of Use shall be referred to and finally and
              exclusively resolved by arbitration in accordance with the
              Arbitration and Conciliation Act, 1996 or any statutory
              modification or re-enactment thereof for the time being in force.
              The arbitration shall be held at New Delhi and all proceedings in
              any such arbitration shall be conducted in English. There shall be
              3 (three) arbitrators (“Arbitrators”), all of whom shall be fluent
              in English. Within thirty 30 (thirty) Days of the reference of the
              dispute to arbitration, the Party raising the dispute and making
              the reference to arbitration shall appoint one Arbitrator and the
              other Party shall appoint the other Arbitrator. The third
              Arbitrator shall be appointed by the 2 (two) appointed
              Arbitrators. The arbitral award shall be final and binding upon
              the parties. The Parties shall equally bear the costs and expenses
              for the conduct of the arbitration proceedings, however; each
              Party shall bear their own legal expenses.
            </span>
          </div>
          <h3> 18. Amendments to the Terms of Use</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>18.1</b> We reserve the right to amend the Terms of Use from
              time to time. Any amendment that is made will come into effect
              from the moment it is displayed on the Mynt Website/App/Platform.
              The updated version of the Terms of Use shall supersede any of the
              previous versions of the Terms of Use.
            </span>
            <span className="paragraph-terms-condition">
              <b>18.2</b> We shall make reasonable efforts to notify the members
              of such changes, however, it shall be your responsibility to be
              updated with the Terms of Use at all times.
            </span>
            <span className="paragraph-terms-condition">
              <b>18.3</b> The continued use of the Mynt Website/App/Platform
              shall amount to your acceptance to the Terms of Use of the Mynt
              Website/App/Platform.
            </span>
          </div>
          <h3>19. Disclaimer</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>19.1</b> Any transaction in securities that companies may offer
              or conclude with any other member of the platform shall be
              offered, issued, allotted or transferred in strict compliance of
              all applicable laws. It shall be the company's responsibility to
              comply with the provisions of applicable laws together with the
              members.
            </span>

            <span className="paragraph-terms-condition">
              <b>19.2</b> Our platform has an internal mechanism to restrict the
              number of Users that view the detailed profile to 200 by default
              thereby making it compliant with the applicable laws. However, it
              shall be the company's responsibility to comply with the
              provisions of applicable laws together with the members. i.
            </span>
            <span className="paragraph-terms-condition">
              <b>19.3</b> Nothing on this Mynt Website/App/Platform is intended
              to constitute (i) an offer, or solicitation of an offer, to
              purchase or sell any security, other asset or service, (ii)
              investment advice or an offer to provide such advice, or (iii) a
              basis for making any investment decision. Neither this Mynt
              Website/App/Platform nor any of the materials make any effort to
              present a comprehensive or balanced description of MXi or its
              investment activities.
            </span>
          </div>
          <h3>20. Miscellaneous</h3>
          <div className="list-items-container-terms">
            <span className="paragraph-terms-condition">
              <b>20.1 No partnership or agency:</b> The Terms of Use shall not
              be construed so as to create a partnership or joint venture
              between you and us. Nothing in the Terms of Use shall be construed
              so as to constitute you and us as agents of one another.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.2 Severability:</b> Each and every obligation under the
              Terms of Use shall be treated as a separate obligation and shall
              be severally enforceable as such in the event of any obligation or
              obligations being or becoming unenforceable in whole or in part.
              To the extent that any provision or provisions of the Terms of Use
              are unenforceable, both of us shall endeavor to amend such clauses
              as may be necessary to make the provision or provisions valid and
              effective. Notwithstanding the foregoing, any provision which
              cannot be amended as may be necessary to make it valid and
              effective shall be deemed to be deleted from the Terms of Use and
              any such deletion shall not affect the enforceability of the
              remainder of the Terms of Use not so deleted provided the
              fundamental terms of the Terms of Use are not altered.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.3 Non-Exclusive Remedies:</b> The rights and remedies herein
              provided are cumulative and none is exclusive of any other, or of
              any rights or remedies that any of us may otherwise have at law or
              in equity. The rights and remedies of any of us based upon,
              arising out of or otherwise in respect of any inaccuracy or breach
              of any representation, warranty, covenant or agreement or failure
              to fulfill any condition shall in no way be limited by the fact
              that the act, omission, occurrence or another state of facts upon
              which any claim of any such inaccuracy or breach is based may also
              be the subject matter of any other representation, warranty,
              covenant or agreement as to which there is no inaccuracy or
              breach.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.4 Partial Invalidity:</b> If any provision of the Terms of
              Use or the application thereof to any person or circumstance shall
              be invalid or unenforceable to any extent for any reason including
              by reason of any law or regulation or government policy, the
              remainder of the Terms of Use and the application of such
              provision to persons or circumstances other than those as to which
              it is held invalid or unenforceable shall not be affected thereby,
              and each provision of the Terms of Use shall be valid and
              enforceable to the fullest extent permitted by law.Any invalid or
              unenforceable provision of the Terms of Use shall be replaced with
              a provision, which is valid and enforceable and most nearly
              reflects the original intent of the invalid and unenforceable
              provision.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.5 Rights of Third Parties: </b> Nothing expressed or implied
              in the Terms of Use is intended or shall be construed to confer
              upon or give any person, other than us hereto any rights or
              remedies under or by reason of the Terms and Conditions or any
              transaction contemplated by the Terms of Use.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.6 Time of Essence:</b> Time shall be of the essence of all
              the matters arising out of or in connection with the Terms of Use.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.7 Execution:</b> The Terms of Use shall be deemed duly
              executed and shall become effective and binding upon you and us
              when you access the Website.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.8 </b>None of the provisions of this Terms, notices or the
              right to use the Mynt Website/App/Platform or Service by the User
              contained herein or any other section or pages of the Mynt
              Website/App/Platform or Service and/or the Linked Sites, shall be
              deemed to constitute a partnership between the User and MXi and no
              party shall have any authority to bind or shall be deemed to be
              the agent of the other in any way.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.9 </b>MXi reserves the right to change, modify, add, amend
              or remove portions of this Agreement (each, a change and
              collectively, changes) and the Website/App/Platform or Service at
              any time. The changes will become effective, and shall be deemed
              accepted by You, immediately after the initial posting and shall
              apply immediately on a going- forward basis with respect to your
              use of the MXi Platform, availing the MXi Services or for payment
              transactions initiated after the posting date. If you do not agree
              with any such change, your sole and exclusive remedy is to
              terminate your use of the MXi Services/MXi Platform. Your
              continued use of the MXi Platform following the posting of changes
              will mean that You accept and agree to the changes.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.10 Confidential Information:</b> (a) User acknowledges that
              the continued success of Mynt Website/App/Platform or Service, the
              Company and their subsidiaries and affiliates, depends upon the
              use and protection of a large body of confidential and proprietary
              information. All of such confidential and proprietary information
              now existing or to be developed in the future will be referred to
              in this Agreement as “Confidential Information.” Confidential
              Information will be interpreted as broadly as possible to include
              all information of any sort (whether merely remembered or embodied
              in a tangible or intangible form) that is (i) related to Mynt
              Website/App/Platform or Service, the Company’s or their
              subsidiaries’ or affiliates’ current or potential business, and
              (ii) is not generally or publicly known. Confidential Information
              includes, without specific limitation, the information,
              observations and data obtained by User whether before or after the
              date of this Agreement concerning the business and affairs of Mynt
              Website/App/Platform or Service, the Company and their
              subsidiaries and affiliates, or of which User becomes aware, the
              persons or entities that are current, former or prospective
              business relations, as well as development, transition and
              transformation plans, methodologies and methods of doing business,
              strategic, marketing and expansion plans, including plans
              regarding planned and potential sales, financial and business
              plans, lists, computer systems, coding, designs, and telephone
              numbers, locations of sales agents, new and existing programs and
              services, prices and terms, merchant service, integration
              processes, requirements and costs of providing service, support
              and equipment. Therefore the User agrees that they shall not
              disclose to any unauthorized person or use for their own account
              any of such Confidential Information without the Company’s prior
              written consent, unless and to the extent that any Confidential
              Information (i) becomes generally known to and available for use
              by the public other than as a result of User’s acts or omissions
              to act, or (ii) is required to be disclosed pursuant to any
              applicable law or court order. Return of confidential information:
              Any materials or documents that have been furnished by the MXi to
              You in connection with the Agreement will be promptly returned by
              the You, accompanied by all copies of such documentation and any
              derivative works thereof at the MXi’s option, upon expiration of
              this Agreement or termination or upon the MXi’s written request.
              In case of destruction, You shall certify the fact of such
              destruction in writing to the MXi, if so requested by the MXi. You
              shall not make any copies of Confidential Information unless the
              same are previously approved in writing by the MXi. You shall
              notify the MXi immediately within 2 (two) days upon discovery of
              any unauthorized use or disclosure of Confidential Information or
              any other breach of this Agreement by You or any third party, and
              will cooperate with MXi in every reasonable way to help MXi regain
              possession of the Confidential Information and prevent its further
              unauthorized use or disclosure.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.11</b>Subject to your compliance with this Agreement’s
              terms, the Company grants you a non-exclusive, non-transferable
              license to use the Mynt Website/App/Platform or Service. MXi does
              not permit and sell to you, and you do not have the right to sub
              license the use of Mynt Website/App/Platform or Service to any
              third-party. The Company can revoke or terminate this licence at
              any time if you use its IP in a manner prohibited by this
              Agreement. You recognize and agree that nothing contained in this
              Agreement shall be construed as granting any property rights, by
              license or otherwise, to any Confidential Information of MXi
              disclosed pursuant to this Agreement, or to any invention or any
              patent, copyright, trademark, or other intellectual property right
              that has issued or that may issue, based on such Confidential
              Information except the limited right to use such Confidential
              Information in accordance with the Services under this Agreement.
              You shall not make, have made, use, or sell for any purpose any
              product or other item using, incorporating, or deriving from any
              Confidential Information of MXi.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.12 </b>MXi does not monitor your use to the Mynt
              Website/App/Platform or Service on a daily basis but reserves the
              right to do so. You acknowledge that MXi will have the right to
              report to law enforcement authorities for any actions that may be
              considered illegal, as well as any information it receives of such
              illegal conduct. When requested, MXi will co-operate fully with
              law enforcement agencies in any investigation of alleged illegal
              activity on the Internet.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.13 </b>Submissions and unauthorised use of any materials
              contained on this Site/App may violate copyright laws, trademark
              laws, the laws of privacy and publicity, certain communications
              statutes and regulations and other applicable laws and
              regulations. You alone are responsible for your actions or the
              actions of any person using your user name and/or password. As
              such, You shall indemnify and hold MXi and its officers,
              directors, employees, affiliates, agents, licensors, and business
              partners harmless from and against any and all loss, costs,
              damages, liabilities, and expenses (including attorneys’ fees)
              incurred in relation to, arising from, or for the purpose of
              avoiding, any claim or demand from a third party that your use of
              the Mynt Website/App/Platform or Service or the use of the Mynt
              Website/App/Platform or Service by any person using your user name
              and/or password (including without limitation your participation
              in the posting areas or your Submissions) violates any applicable
              law or regulation, or the rights of any third party.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.14 </b>MXi reserves the right to terminate access to this
              Site/App at any time and without notice. Further this limited
              license terminates automatically, without notice to you, if you
              breach any of these Terms. Upon termination, you must immediately
              destroy any downloaded and printed materials.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.15 </b>MXi may change the terms and conditions and
              disclaimer set out above from time to time. By browsing this Mynt
              Website/App/Platform or Service you are accepting that you are
              bound by the current terms and conditions and disclaimer and so
              you should check these each time you revisit the Mynt
              Website/App/Platform or Service.
            </span>

            <span className="paragraph-terms-condition">
              <b>20.16 </b>MXi can, at its own discretion, change the format and
              content of this Mynt Website/App/Platform or Service at any time.
              MXi may suspend the operation of this Mynt Website/App/Platform or
              Service for support or maintenance work, in order to update the
              Content or for any other reason.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.17 </b>Personal details provided to MXi through this
              Site/App will only be used in accordance with our privacy policy.
              Please read this carefully before going on. By providing your
              personal details to us you are consenting to its use in accordance
              with our privacy policy.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.18 </b>MXi reserves all other rights.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.19 </b>MXi may at any time, in its sole discretion and
              without advance notice to you, cease operation of the Mynt
              Website/App/Platform or Service and distribution of the Services.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.20 </b>You may not assign your rights and obligations under
              this Agreement to anyone.
            </span>
            <span className="paragraph-terms-condition">
              <b>20.21</b>We aim to keep our information about you as accurate
              as possible. If you have any questions about these Terms and
              Conditions or the Mynt Website/App/Platform or Service, please
              contact us through the contact details listed on the Website with
              the Subject of the email: USER Name and Terms and Conditions
            </span>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
export default TermsAndCondition;
