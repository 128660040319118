import React from 'react'

const PageNotFound = () => {
    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2>404! Page Not Found!</h2>
        </div>
    )
}

export default PageNotFound