import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../../css/LiveDealsDetails/liveDetails.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Pinterest from "../../images/highlights/pinterest.png";
import Twitter from "../../images/highlights/twitter.png";
import Facebook from "../../images/highlights/facebook.png";
import Linkdin from "../../images/highlights/linkdin.png";
import Globe from "../../images/highlights/globe.png";
import Instagram from "../../images/highlights/instagram.png";
export default function DealTerm({ blurAmount, dealTermData, companyData }) {
  const [gridxsMainFirst, setGridxsMainFirst] = useState(3);
  const [gridxsMainSecond, setGridxsMainSecond] = useState(4);
  const [gridxsMainThird, setGridxsMainThird] = useState(3);
  const [gridxsFirst, setGridxsFirst] = useState(4);
  const [gridxsSecond, setGridxsSecond] = useState(3);
  const [gridxsThird, setGridxsThird] = useState(4);
  const ratio = parseInt(window.innerWidth);
  console.log(dealTermData);
  useEffect(() => {
    if (ratio < 1000) {
      setGridxsMainThird(2);
      setGridxsThird(6);
    }
    if (ratio < 760) {
      setGridxsMainSecond(2);
      setGridxsSecond(6);
    }
    if (ratio < 630) {
      setGridxsMainFirst(2);
      setGridxsFirst(6);
      setGridxsMainThird(1);
      setGridxsThird(12);
    }
    if (ratio < 400) {
      setGridxsMainSecond(1);
      setGridxsSecond(12);
      setGridxsMainFirst(1);
      setGridxsFirst(12);
    }
  }, []);

  console.log("deal_term", dealTermData)
  return (
    <>
      <Grid
        container
        spacing={gridxsMainFirst}
        style={{ filter: `blur(${blurAmount}px)` }}
      >
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">Type</span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_type?.deal_name}
            </span>
            <span className="subHead-main-deal-terms">
              {dealTermData?.deal_type?.deal_description}
            </span>
          </div>
        </Grid>
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">Discount %</span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_terms?.discount}
            </span>
          </div>
        </Grid>
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">Valuation Cap</span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_terms?.valuation_cap}
            </span>
            <span className="subHead-main-deal-terms">
              A valuation cap is a maximum value that a company is willing to
              accept for a round of financing or Subscription. It is the highest
              price at which a company is willing to sell its equity to
              investors
            </span>
          </div>
        </Grid>
        {/* <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">P</span>
            <span className="mainHead-main-deal-terms">{"-"}</span>
            <span className="subHead-main-deal-terms">
              "Pre-money valuation" refers to the value of a company before it
              receives any external funding. This term is commonly used in the
              context of startups that are seeking funds from potential
              investors. For instance, if a startup has a pre-money valuation of
              $10 million and receives an investment of $2 million, the
              resulting post-money valuation (the value of the company after the
              investment) would be $12 million.
            </span>
          </div>
        </Grid> */}
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">Minimum Enrollment </span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_terms?.min_subscription}
            </span>
            <span className="subHead-main-deal-terms">
              Only amounts equal to or greater than the minimum will be
              accepted.
            </span>
          </div>
        </Grid>
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">Target</span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_terms?.target}
            </span>
            <span className="subHead-main-deal-terms">
              This is the amount the startup is looking to raise
            </span>
          </div>
        </Grid>
        <Grid item xs={gridxsFirst}>
          <div className="deal-terms-conatiner">
            <span className="header-main-deal-terms">End Date</span>
            <span className="mainHead-main-deal-terms">
              {dealTermData?.deal_terms?.end_date}
            </span>
          </div>
        </Grid>
      </Grid>
      <div className="documents-deal-terms-section">
        <span style={{ fontSize: "18px", fontWeight: 600, marginBottom: 20 }}>
          Documents
        </span>
        {/* <span style={{ fontSize: '16px', marginTop: '21px', marginBottom: '43px' }}>A due diligence report is a document prepped by an independent third party due diligence team which includes information related to financials compliance, key risks and a lot more.</span> */}
        <Grid container spacing={gridxsMainSecond}>
          {
            companyData?.documents?.filter(val => val.document_type === "DOCUMENTS").map((item, index) => {
              return (
                <Grid item key={index} xs={gridxsSecond}>
                  <div className="box-documents-download-pdf">
                    <span
                      className="live-deals-details-decription"
                      style={{ marginRight: "10px", color: "black" }}
                    >
                      {item?.document_name}
                    </span>
                    <a href={item?.document_url} target="_blank">
                      <DownloadForOfflineIcon
                        style={{ color: "#EBB429", width: "35px", height: "35px" }}
                      />
                    </a>

                  </div>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
      <div className="documents-deal-terms-section">
        {companyData?.company_name && (
          <span style={{ fontSize: "18px", fontWeight: 600, marginBottom: 20 }}>
            About company
          </span>
        )}
        <Grid container spacing={gridxsMainThird}>
          {companyData?.company_name && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">Legal Name</span>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {companyData?.company_name}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.invested_so_far && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">Founded</span>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {companyData?.invested_so_far}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.incorporation_type && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">Form</span>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {companyData?.incorporation_type}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.number_of_employees && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">
                  {" "}
                  Number of employees
                </span>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {companyData?.number_of_employees}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.website_url && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">Website</span>
                <span style={{ fontSize: "22px", fontWeight: "600", overflowWrap: 'anywhere' }}>
                  {companyData?.website_url}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.company_address && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                <span className="header-main-deal-terms">Location</span>
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {" "}
                  {companyData?.company_address}
                </span>
              </div>
            </Grid>
          )}
          {companyData?.company_linked_in_profile && (
            <Grid item xs={gridxsThird}>
              <div className="about-company-cards-deal-terms">
                {/* <span className="header-main-deal-terms">Social Media</span>
                            <span style={{ fontSize: '22px', fontWeight: '600' }}>{companyData?.company_linked_in_profile}</span> */}
                <div className="social-media-icons-deal-terms">
                  <a
                    href={companyData?.company_linked_in_profile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      src={Linkdin}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "1rem",
                      }}
                    ></img>{" "}
                  </a>

                  <a
                    href={companyData?.facebook_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Facebook}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "1rem",
                      }}
                    ></img>{" "}
                  </a>

                  <a
                    href={companyData?.instagram_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Instagram}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "1rem",
                      }}
                    ></img>{" "}
                  </a>

                  <a
                    href={companyData?.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Globe}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "1rem",
                      }}
                    ></img>{" "}
                  </a>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        {/* <div className="social-media-icons-deal-terms">
          <a
            href={companyData?.company_linked_in_profile}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img
              src={Linkdin}
              style={{ width: "40px", height: "40px", marginRight: "51px" }}
            ></img>{" "}
          </a>

          <a
            href={companyData?.company_linked_in_profile}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Facebook}
              style={{ width: "40px", height: "40px", marginRight: "51px" }}
            ></img>{" "}
          </a>

          <a
            href={companyData?.company_linked_in_profile}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Pinterest}
              style={{ width: "40px", height: "40px", marginRight: "51px" }}
            ></img>{" "}
          </a>

          <a
            href={companyData?.company_linked_in_profile}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Twitter}
              style={{ width: "40px", height: "40px", marginRight: "51px" }}
            ></img>{" "}
          </a>
        </div> */}
      </div>
    </>
  );
}
