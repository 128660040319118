export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const UPDATE_LOGIN_REQUEST = "UPDATE_LOGIN_REQUEST";
export const UPDATE_LOGIN_SUCCESS = "UPDATE_LOGIN_SUCCESS";
export const UPDATE_LOGIN_FAILED = "UPDATE_LOGIN_FAILED";

export const USER_EMAIL_REQUEST = "USER_EMAIL_REQUEST"
export const USER_EMAIL_SUCCESS = "USER_EMAIL_SUCCESS"
export const USER_EMAIL_FAILED = "USER_EMAIL_FAILED"