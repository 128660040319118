export const VERIFY_PAN_REQUEST = "VERIFY_PAN_REQUEST";
export const VERIFY_PAN_SUCCESS = "VERIFY_PAN_SUCCESS";
export const VERIFY_PAN_FAILED = "VERIFY_PAN_FAILED";

export const VERIFY_BANK_REQUEST = "VERIFY_BANK_REQUEST";
export const VERIFY_BANK_SUCCESS = "VERIFY_BANK_SUCCESS";
export const VERIFY_BANK_FAILED = "VERIFY_BANK_FAILED";

export const STORE_KYC_REQUEST = "STORE_KYC_REQUEST";
export const STORE_KYC_SUCCESS = "STORE_KYC_SUCCESS";
export const STORE_KYC_FAIL = "STORE_KYC_FAIL";

export const EDIT_KYC_REQUEST = "STORE_KYC_REQUEST";
export const EDIT_KYC_SUCCESS = "STORE_KYC_SUCCESS";
export const EDIT_KYC_FAIL = "STORE_KYC_FAIL";


